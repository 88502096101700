import React, { useState, useEffect, useRef } from "react";
import LogoImage from "./../styles/images/logo.jpg";
import axios from "axios";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../constants";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const InvoiceReceipt = () => {
  const { id } = useParams();
  const [invoice, setInvoice] = useState({});
  const baseURL = BASE_URL;
  const token = localStorage.getItem("erptoken");

  const invoiceRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/Invoices/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(response.data);
        setInvoice(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [baseURL, token, id]);

const downloadInvoice = () => {
    const input = invoiceRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'pt', 'a4');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 10, 10, pdfWidth - 20, pdfHeight - 20);
      pdf.save(`${id}.pdf`);
    });
  };
  
  

  return (
    <>
      <div className="container text-right">
        <span onClick={downloadInvoice} className="btn btn-warning my-3">
          Download Invoice
        </span>
      </div>
            <div className="container" ref={invoiceRef}>
              <div className="card bg-white text-black p-3">
                <div
                  className="card p-1 bg-white"
                  style={{ border: "4px solid black" }}
                >
                  <div
                    className="card bg-white p-4"
                    style={{ border: "2px solid black" }}
                  >
                    <div className="d-flex justify-content-between">
                      <img src={LogoImage} style={{ width: "150px" }} />
                      <div>
                        <h1
                          style={{ "font-size": "80px" }}
                          className="text-danger m-0"
                        >
                          E<span style={{ "font-size": "60px" }}>KT</span>A C
                          <span style={{ "font-size": "60px" }}>LASSE</span>S
                        </h1>
                        <p
                          className="text-center m-0"
                          style={{ "font-size": "30px" }}
                        >
                          A class you can Trust
                        </p>
                      </div>
                      <div>
                        <p className="fw-bold" style={{ "font-size": "16px" }}>
                          +91 9324 9342 09
                          <br />
                          +91 8108 2745 08
                        </p>
                      </div>
                    </div>
                    <h4 className="text-center mt-4">
                      Shop No. 06, Sheetal Complex, Indralok Phase III,
                      Bhayandar (E), Thane-401 105. Branch: Shop No. 14, Raj
                      Vaibhav Bldg., Indralok Phase VI, Bhayandar (E), Thane-401
                      105
                    </h4>
                    <h2 className="text-danger text-center mt-3">
                      FEE RECEIPT
                    </h2>
                    <div className="d-flex justify-content-between mt-4">
                      <h5 className="fw-bold">Receipt Id. {invoice.id}</h5>
                      <h5 className="fw-bold">
                        Date:{" "}
                        {new Date(invoice.createdAt).toLocaleDateString(
                          "en-IN",
                          {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                          }
                        )}
                      </h5>
                    </div>
                    <div className="mt-3">
                      <style>
                        {`
      div input {
        font-weight: 600;
      }
    `}
                      </style>
                      <p style={{ "font-size": "20px" }}>
                        Received with thanks from Mr./Miss
                        <input
                          className="ml-2"
                          value={invoice.studentName}
                          style={{
                            "border-top": "0px",
                            "border-bottom": "2px solid black",
                            "border-right": "0px",
                            "border-left": "0px",
                            width: "65%",
                          }}
                        />
                      </p>
                      <p style={{ "font-size": "20px" }}>
                        The sum of Rupees (in words)
                        <input
                          className="ml-2"
                          value={invoice.amountInWords}
                          style={{
                            "border-top": "0px",
                            "border-bottom": "2px solid black",
                            "border-right": "0px",
                            "border-left": "0px",
                            width: "71%",
                          }}
                        />
                      </p>
                      <p style={{ "font-size": "20px" }}>
                        By Cash / Cheque No.
                        <input
                          value={invoice.cashCheque}
                          className="ml-2"
                          style={{
                            "border-top": "0px",
                            "border-bottom": "2px solid black",
                            "border-right": "0px",
                            "border-left": "0px",
                            width: "30%",
                          }}
                        />
                        Drawn on{" "}
                        <input
                          value={
                            invoice.drawnOn != null
                              ? new Date(invoice.drawnOn).toLocaleDateString(
                                  "en-IN"
                                )
                              : null
                          }
                          className="ml-2"
                          style={{
                            "border-top": "0px",
                            "border-bottom": "2px solid black",
                            "border-right": "0px",
                            "border-left": "0px",
                            width: "38%",
                          }}
                        />
                      </p>
                      <p style={{ "font-size": "20px" }}>
                        Dated
                        <input
                          className="ml-2"
                          value={new Date(invoice.date).toLocaleDateString(
                            "en-IN"
                          )}
                          style={{
                            "border-top": "0px",
                            "border-bottom": "2px solid black",
                            "border-right": "0px",
                            "border-left": "0px",
                            width: "40%",
                          }}
                        />
                        in Payment of Part / Full.{" "}
                        <span className="ml-5">
                          Std.
                          <input
                            value={invoice.standard}
                            className="ml-2"
                            style={{
                              "border-top": "0px",
                              "border-bottom": "2px solid black",
                              "border-right": "0px",
                              "border-left": "0px",
                              width: "20%",
                            }}
                          />
                        </span>
                      </p>
                      <p style={{ "font-size": "20px" }}>
                        Batch (Morn./Eve.)
                        <input
                          value={invoice.batch}
                          className="ml-2"
                          style={{
                            "border-top": "0px",
                            "border-bottom": "2px solid black",
                            "border-right": "0px",
                            "border-left": "0px",
                            width: "20%",
                          }}
                        />{" "}
                        Bal. Rs.{" "}
                        <input
                          value={invoice.amount}
                          className="ml-2"
                          style={{
                            "border-top": "0px",
                            "border-bottom": "2px solid black",
                            "border-right": "0px",
                            "border-left": "0px",
                            width: "20%",
                          }}
                        />{" "}
                        Payable Before{" "}
                        <input
                          value={
                            invoice.payableBefore != null
                              ? new Date(
                                  invoice.payableBefore
                                ).toLocaleDateString("en-IN")
                              : null
                          }
                          className="ml-2"
                          style={{
                            "border-top": "0px",
                            "border-bottom": "2px solid black",
                            "border-right": "0px",
                            "border-left": "0px",
                            width: "17%",
                          }}
                        />
                      </p>
                      <p style={{ "font-size": "20px" }}>
                        Medium :{" "}
                        <input
                          value={invoice.medium}
                          className="ml-2"
                          style={{
                            "border-top": "0px",
                            "border-bottom": "2px solid black",
                            "border-right": "0px",
                            "border-left": "0px",
                            width: "20%",
                          }}
                        />
                      </p>
                    </div>
                    <div className="d-flex mt-3 align-items-center">
                      <div
                        className="px-3 py-1 d-flex align-items-center"
                        style={{
                          border: "3px solid red",
                          borderRadius: "10px",
                        }}
                      >
                        <h1 className="m-0">₹</h1>
                        <h1 className="ml-2 m-0">{invoice.amount}</h1>
                      </div>
                      <div className="px-3">
                        <p className="m-0">
                          1. Payment by Cheque subject to realization
                        </p>
                        <p className="m-0">
                          2. Fees once paid will not be transferable &
                          refundable in any circumstances
                        </p>
                      </div>
                    </div>
                    <div className="mt-4">
                      <h4 className="text-danger m-0">
                        Note: The invoice is generated electronically and
                        therefore does not require a signature.
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    </>
  );
};

export default InvoiceReceipt;
