import React , {useState , useEffect} from 'react';
import { Formik, Form, Field, ErrorMessage , resetForm } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from 'react-toastify';
import { BASE_URL } from '../constants';
import DeleteModal from "./DeleteModal";


const Invoice = () => {

  const [invoiceList , setInvoiceList] = useState([]);
    const [togglemodal , setToggleModal] = useState(false);
    const [invoiceId, setInvoiceId] = useState(null);
    const [editmode, setEditMode] = useState(false);
    const [initialValues , setInitialValues] = useState({
        date : "",
        studentName: "",
        standard: "",
        cashCheque: "",
        drawnOn: "",
        batch:"",
        medium:"",
        amount: "",
        amountInWords:"",
        payableBefore:"",
      });
    const [initialValuesVersion, setInitialValuesVersion] = useState(0);
    const baseURL = BASE_URL; 
    const token = localStorage.getItem('erptoken');

    const validationSchema = Yup.object().shape({
      date: Yup.date().required('Date is required'),
      studentName: Yup.string().required('Student Name is required'),
      standard: Yup.string().required('Standard is required'),
      amount: Yup.number().typeError('Amount must be a number'),
    });

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${baseURL}/api/Invoices`,{
            headers: {
              Authorization: `Bearer ${token}`,
            }
          });
          setInvoiceList(response.data);
        } catch (error) {
          console.error(error);
        }
      };
      fetchData();
    },[baseURL, token]);

    const onSubmit = async (values , { resetForm } ) => {
      if (!values.drawnOn) {
        values.drawnOn = null;
      }
      if (!values.payableBefore) {
        values.payableBefore = null;
      }

      try {
          const response = await axios.post(`${baseURL}/api/Invoices`, values ,{
              headers: {
              Authorization: `Bearer ${token}`,
              },
          });
          setInvoiceList(invoiceList => [...invoiceList, response.data]);
          resetForm({ values: null });
          document.querySelector("#addinvoice").style.display = "none";
          toast.success("Invoice has been Generated")
      } catch (error) {
          console.error(error);
          toast.error(error);
      }
};


    const handleDeleteModal = (key) => {
      setInvoiceId(key);
      setToggleModal(true);
    };
    
    const handleDelete = () => {
      axios.delete(`${baseURL}/api/Invoices/${invoiceId}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      .then(response => {
        toast.success("Enquiry has been Deleted..");
        setInvoiceList(invoiceList.filter((a) => a.id !== invoiceId));
        setInvoiceId(null);
        setToggleModal(false);
      })
      .catch(error => {
        console.error(error);
        toast.success(error);
        setInvoiceId(null);
        setToggleModal(false);
      });
    }

    const handleEditModal = (key) => {
      setEditMode(true);
      setInvoiceId(key);
      document.querySelector("#addinvoice").style.display = "block";
      const editinvoice = invoiceList.filter(a => a.id === key);
      console.log(editinvoice[0].date);
      const editedValues = {
          date: editinvoice[0].date.substring(0, 10),
          studentName: editinvoice[0].studentName,
          standard: editinvoice[0].standard,
          cashCheque: editinvoice[0].cashCheque,
          drawnOn: editinvoice[0].drawnOn != null ? editinvoice[0].drawnOn.substring(0, 10) : null,
          batch: editinvoice[0].batch,
          medium: editinvoice[0].medium,
          amount: editinvoice[0].amount,
          amountInWords: editinvoice[0].amountInWords,
          payableBefore: editinvoice[0].payableBefore != null ? editinvoice[0].payableBefore.substring(0, 10) : null,
        };
        setInitialValues(editedValues);
        setInitialValuesVersion((v) => v + 1);
    };

    const onEdit = async (values, { resetForm }) => {
      if (!values.drawnOn) {
        values.drawnOn = null;
      }
      if (!values.payableBefore) {
        values.payableBefore = null;
      }
      try {
        const response = await axios.put(`${baseURL}/api/Invoices/${invoiceId}`, values ,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const updatedList = invoiceList.map((invoice) => {
          if (invoice.id === response.data.id) {
            return response.data;
          } else {
            return invoice;
          }
        });
    
        setInvoiceList(updatedList);

        resetForm({ values: null });
        setInvoiceId(null);
        document.querySelector("#addinvoice").style.display = "none";
        toast.success("Invoice Changed..")
      } catch (error) {
        console.error(error);
        toast.error(error);
      }
    };



    function convertNumberToWords(num) {
      const ones = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
      const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
      
      if (num === 0) {
        return 'zero';
      }
      
      if (num < 0) {
        return 'minus ' + convertNumberToWords(Math.abs(num));
      }
      
      let words = '';
      
      if (Math.floor(num / 10000000)) {
        words += convertNumberToWords(Math.floor(num / 10000000)) + ' Crore ';
        num %= 10000000;
      }
      
      if (Math.floor(num / 100000)) {
        words += convertNumberToWords(Math.floor(num / 100000)) + ' Lakh ';
        num %= 100000;
      }
      
      if (Math.floor(num / 1000)) {
        words += convertNumberToWords(Math.floor(num / 1000)) + ' Thousand ';
        num %= 1000;
      }
      
      if (Math.floor(num / 100)) {
        words += convertNumberToWords(Math.floor(num / 100)) + ' Hundred ';
        num %= 100;
      }
      
      if (num >= 20) {
        words += tens[Math.floor(num / 10)] + ' ';
        num %= 10;
      }
      
      if (num > 0) {
        words += ones[num] + ' ';
      }
      
      return words.trim();
    }
    
    

      
  return (
    <>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center pb-4">
              <h4 className="card-title m-0">Generate Invoice</h4>
                <button
                className="btn btn-info"
                onClick={()=> {
                    document.querySelector("#addinvoice").style.display = "block";
                    setInitialValues({
                      date : "",
                      studentName: "",
                      standard: "",
                      cashCheque: "",
                      drawnOn: "",
                      batch:"",
                      medium:"",
                      amount: "",
                      amountInWords:"",
                      payableBefore:"",
                     });
                     setInitialValuesVersion(initialValuesVersion + 1);
                    setEditMode(false);
                  }}
              >
                <span className="mdi mdi-plus mr-1"></span>
                <span>Generate Invoice</span>
              </button>
              
            </div>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Student Name</th>
                    <th>Standard</th>
                    <th>Cash/Cheque</th>
                    <th>Drawn On</th>
                    <th>Batch</th>
                    <th>Medium</th>
                    <th>Amount</th>
                    <th>Amount In Words</th>
                    <th>Payable Before</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                {invoiceList.map((data) => (
                    <tr key={data.id}>
                      <td>{new Date(data.date).toLocaleDateString('en-IN')}</td>
                      <td>{data.studentName}</td>
                      <td>{data.standard}</td>
                      <td>{data.cashCheque}</td>
                      <td>{data.drawnOn != null ? new Date(data.drawnOn).toLocaleDateString('en-IN') : null}</td>
                      <td>{data.batch}</td>
                      <td>{data.medium}</td>
                      <td>{data.amount}</td>
                      <td>{data.amountInWords}</td>
                      <td>{data.payableBefore != null ? new Date(data.payableBefore).toLocaleDateString('en-IN') : null}</td>
                      <td>
                       <a
                          href="javascript:void(0)"
                          onClick={() => handleDeleteModal(data.id)}
                          className="mdi mdi-trash-can text-danger display-5"
                        ></a>
                        <a
                          href="javascript:void(0)"
                          onClick={() => handleEditModal(data.id)}
                          className="mdi mdi-pencil text-info display-5 ml-3"
                        ></a>
                        <a
                          href={`/invoice/${data.id}`}
                          // onClick={() => handleGeneratePDF(data.id)}
                          className="mdi mdi-cloud-print text-warning display-5 ml-3"
                        ></a>
                      </td>
                    </tr>
                     ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


      <div className="modal" id="addinvoice" tabIndex="-1" role="dialog">
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{editmode ? 'Edit Invoice' : 'Add Invoice'}</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <Formik
             key={initialValuesVersion}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={editmode ? onEdit : onSubmit}
            >
            {(formik) => (
         <Form>
         <div className="modal-body">
          <div className='row'>

         <div className="form-group  col-lg-6">
             <Field
               type="date"
               className="form-control"
               name="date"
               placeholder="Date"
             />
             <div className="form-error">
             <ErrorMessage name="date" />
             </div>
             
           </div>


           <div className="form-group col-lg-6">
             <Field
               type="text"
               className="form-control"
               name="studentName"
               placeholder="Student Name"
             />
             <div className="form-error">
             <ErrorMessage name="studentName" />
             </div>
           </div>
            </div>

            <div className='row'>
           <div className="form-group col-lg-6">
             <Field
               type="text"
               className="form-control"
               name="standard"
               placeholder="Standard"
             />
             <div className="form-error">
             <ErrorMessage name="standard" />
             </div>
           </div>

           <div className="form-group col-lg-6">
             <Field
               type="text"
               className="form-control"
               name="batch"
               placeholder="Batch"
             />
             <div className="form-error">
             <ErrorMessage name="batch" />
             </div>
           </div>
              </div>

              <div className='row'>
           <div className="form-group col-lg-6">
             <Field
               type="text"
               className="form-control"
               name="cashCheque"
               placeholder="Cash/Cheque"
             />
             <div className="form-error">
             <ErrorMessage name="cashCheque" />
             </div>
           </div>

           <div className="form-group col-lg-6">
             <Field
               type="date"
               className="form-control"
               name="drawnOn"
               placeholder="Drawn On"
             />
             <div className="form-error">
             <ErrorMessage name="drawnOn" />
             </div>
           </div>
              </div>

              <div className='row'>
           <div className="form-group col-lg-6">
             <Field
               type="text"
               className="form-control"
               name="medium"
               placeholder="Medium"
             />
             <div className="form-error">
             <ErrorMessage name="medium" />
             </div>
           </div>

           <div className="form-group col-lg-6">
             <Field
               type="number"
               className="form-control"
               name="amount"
               placeholder="Amount"
               onChange={(e) => {
                formik.setFieldValue('amount', e.target.value);
                formik.setFieldValue('amountInWords', convertNumberToWords(e.target.value));
              }}
             />
             <div className="form-error">
             <ErrorMessage name="amount" />
             </div>
           </div>
           </div>


           <div className="form-group">
             <Field
               type="text"
               className="form-control"
               name="amountInWords"
               placeholder="Amount In Words"
             />
             <div className="form-error">
             <ErrorMessage name="amountInWords" />
             </div>
           </div>

           <div className="form-group">
             <Field
               type="date"
               className="form-control"
               name="payableBefore"
               placeholder="Payable Before"
             />
             <div className="form-error">
             <ErrorMessage name="payableBefore" />
             </div>
           </div>
           
         </div>
         <div className="modal-footer">
           <button type="submit" className="btn btn-primary">
             {editmode ? 'Edit Invoice' : 'Add Invoice' }
           </button>
           <button
             type="button"
             className="btn btn-secondary"
             data-dismiss="modal"
             onClick={() => {
               document.querySelector("#addinvoice").style.display = "none";
               setInitialValues({
                date : "",
                studentName: "",
                standard: "",
                cashCheque: "",
                drawnOn: "",
                batch:"",
                medium:"",
                amount: "",
                amountInWords:"",
                payableBefore:"",
               });
               setInitialValuesVersion(initialValuesVersion + 1);
             }}
           >
             Close
           </button>
         </div>
       </Form>
             )}
             </Formik>
          </div>
        </div>
      </div>

      <DeleteModal toggle={togglemodal} togglehandle={setToggleModal} handleDelete={handleDelete}/>



    </>
  )
}

export default Invoice