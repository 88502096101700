import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import TeacherDetail from "./TeacherDetail";
import { BASE_URL } from "../constants";

const AddTeacher = () => {
  const [teacherList, setTeacherList] = useState([]);
  const [teacherId, setTeacherId] = useState();
  const [teacher, setTeacher] = useState({
    name: "",
    designation: "",
    mobileNumber: "",
    emailId: "",
    address: "",
    resume: null,
  });
  const [togglemodal, setToggleModal] = useState(false);
  const [editmode, setEditMode] = useState(false);

  const baseURL = BASE_URL;
  const token = localStorage.getItem("erptoken");

  const handleChange = (e) => {
    setTeacher({
      ...teacher,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeResume = (event) => {
    const file = event.target.files[0];
    setResumeFile(file);
  };

  const setResumeFile = (file) => {
    setTeacher({
      ...teacher,
      resume: file,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("name", teacher.name);
    formData.append("designation", teacher.designation);
    formData.append("mobileNumber", teacher.mobileNumber);
    formData.append("emailId", teacher.emailId);
    formData.append("address", teacher.address);
    formData.append("resume", teacher.resume);

    try {
      const response = await axios.post(`${baseURL}/api/Teachers`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      setTeacherList([...teacherList, response.data]);
      setTeacher({
        name: "",
        designation: "",
        mobileNumber: "",
        emailId: "",
        address: "",
        resume: null,
      });
      setToggleModal(false);
      document.querySelector(".file-upload-info").value = "";
      toast.success("Teacher has been added Successfully");
      console.log(response.data);
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  };

  const handleEdit = async (event)  => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("name", teacher.name);
    formData.append("designation", teacher.designation);
    formData.append("mobileNumber", teacher.mobileNumber);
    formData.append("emailId", teacher.emailId);
    formData.append("address", teacher.address);
    formData.append("resume", teacher.resume);

    try {
      const response = await axios.put(`${baseURL}/api/Teachers/${teacherId}`, formData ,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const updatedTeacherList = teacherList.map(teacher => {
        if (teacher.id === response.data.id) {
          return response.data;
        }
        return teacher;
      });

      setTeacherList(updatedTeacherList);

      setTeacher({
        name: "",
        designation: "",
        mobileNumber: "",
        emailId: "",
        address: "",
        resume: null,
      });
      
      setTeacherId(null);
      setToggleModal(false);
      setEditMode(false);
      toast.success("Teacher Has been Successfully Edited")
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
    
  }

  const handleuploadbtn = () => {
    var file = document.querySelector('.file-upload-default');
    file.click();
    const fileUploadDefault = document.querySelector('.file-upload-default');
    const formControl = fileUploadDefault.parentElement.querySelector('.form-control');

    fileUploadDefault.addEventListener('change', function() {
      formControl.value = this.value.replace(/C:\\fakepath\\/i, '');
    });
  };


  return (
    <>
      <div className={`modal ${togglemodal ? 'd-block' : 'd-none'}`} id="addteachers" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{editmode ? 'Edit Teacher' : 'Add Teacher' }</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={editmode ? handleEdit : handleSubmit} encType="multipart/form-data">
              <div className="modal-body p-0">
                <div className="row">
                  <div className="col-12 grid-margin mb-0">
                    <div className="card">
                      <div className="card-body">
                            <div className="form-group">
                                <input
                                  type="text"
                                  name="name"
                                  placeholder="Enter Teacher's Name"
                                  required
                                  value={teacher.name}
                                  className="form-control"
                                  onChange={handleChange}
                                />
                          </div>
                            <div className="form-group">
                                <input
                                  type="text"
                                  placeholder="Enter Teacher's Designation"
                                  name="designation"
                                  value={teacher.designation}
                                  className="form-control"
                                  onChange={handleChange}
                                />
                        </div>
                            <div className="form-group ">
                                <input
                                  type="text"
                                  placeholder="Enter Teacher's Mobile Number"
                                  name="mobileNumber"
                                  required
                                  value={teacher.mobileNumber}
                                  className="form-control"
                                  onChange={handleChange}
                                />
                            </div>
                            <div className="form-group ">
                                <input
                                  type="email"
                                  placeholder="Enter Email ID"
                                  name="emailId"
                                  value={teacher.emailId}
                                  className="form-control"
                                  onChange={handleChange}
                                />
                              </div>

                            <div className="form-group">
                                <input
                                  type="text"
                                  name="address"
                                  placeholder="Enter Address"
                                  value={teacher.address}
                                  className="form-control"
                                  onChange={handleChange}
                                />
                              </div>
                            <div className="form-group">
                                <input
                                  type="file"
                                  name="img[]"
                                  onChange={handleChangeResume}
                                  className="file-upload-default"
                                />
                                <div className="input-group col-xs-12">
                                  <input
                                    type="text"
                                    className="form-control file-upload-info"
                                    value={teacher.resume ? teacher.resume.name : ''}
                                    disabled
                                    placeholder="Upload Resume"
                                  />
                                  <span className="input-group-append">
                                    <button
                                      className="file-upload-browse btn btn-primary"
                                      type="button"
                                      onClick={handleuploadbtn}
                                    >
                                      Upload
                                    </button>
                                  </span>
                                </div>
                              </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-primary">
                  {editmode ? 'Edit Teacher' : 'Add Teacher'}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => {
                   setToggleModal(false)
                   setTeacher({
                    name: "",
                    designation: "",
                    mobileNumber: "",
                    emailId: "",
                    address: "",
                    resume: null,
                  });
                  }}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <TeacherDetail
        teacherlist={teacherList}
        setTeacher={setTeacherList}
        teacherid={teacherId}
        setTeacherid={setTeacherId}
        toggle={setToggleModal}
        setEditMode={setEditMode}
        setTeacherForm={setTeacher}
      />
    </>
  );
};

export default AddTeacher;
