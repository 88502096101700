import React from "react";
import { Link } from "react-router-dom";

const Dashboard = (props) => {
  function importAll(r) {
    let images = {};
    r.keys().map((item, index) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  }

  const images = importAll(
    require.context("../styles/images/faces", false, /\.(png|jpe?g|svg)$/)
  );

  return (
    <>
      <div className="row">
        <Link to="/boardandclass" className="col-xl-3 col-sm-6 grid-margin stretch-card text-white">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-9">
                  <div className="d-flex align-items-center align-self-start">
                    <h5 className="mb-0">Boards</h5>
                  </div>
                </div>
                <div className="col-3">
                  <div className="icon icon-box-success ">
                    <span className="mdi mdi-city icon-item"></span>
                  </div>
                </div>
              </div>
            
            </div>
            </div>
        </Link>
        <Link to="/boardandclass" className="col-xl-3 col-sm-6 grid-margin stretch-card text-white">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-9">
                  <div className="d-flex align-items-center align-self-start">
                    <h5 className="mb-0">Standards</h5>
                  </div>
                </div>
                <div className="col-3">
                  <div className="icon icon-box-success ">
                    <span className="mdi mdi-school icon-item"></span>
                  </div>
                </div>
              </div>
          
            </div>
          </div>
        </Link>
        <Link to="/addteacher" className="col-xl-3 col-sm-6 grid-margin stretch-card text-white">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-9">
                  <div className="d-flex align-items-center align-self-start">
                    <h5 className="mb-0">Teachers</h5>
                  </div>
                </div>
                <div className="col-3">
                  <div className="icon icon-box-success ">
                    <span className="mdi mdi-human icon-item"></span>
                  </div>
                </div>
              </div>
             
            </div>
          </div>
        </Link>
        <Link to="/addstudent" className="col-xl-3 col-sm-6 grid-margin stretch-card text-white">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-9">
                  <div className="d-flex align-items-center align-self-start">
                    <h5 className="mb-0">Students</h5>
                  </div>
                </div>
                <div className="col-3">
                  <div className="icon icon-box-success ">
                    <span className="mdi mdi-human-child icon-item"></span>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </Link>
        <Link to="/classtest" className="col-xl-3 col-sm-6 grid-margin stretch-card text-white">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-9">
                  <div className="d-flex align-items-center align-self-start">
                    <h5 className="mb-0">Class Test</h5>
                  </div>
                </div>
                <div className="col-3">
                  <div className="icon icon-box-success ">
                    <span className="mdi mdi-clipboard icon-item"></span>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </Link>
        <Link to="/studentmarks" className="col-xl-3 col-sm-6 grid-margin stretch-card text-white">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-9">
                  <div className="d-flex align-items-center align-self-start">
                    <h5 className="mb-0">Student Marks</h5>
                  </div>
                </div>
                <div className="col-3">
                  <div className="icon icon-box-success ">
                    <span className="mdi mdi-checkbox-marked-outline icon-item"></span>
                  </div>
                </div>
              </div>
            
            </div>
          </div>
        </Link>
        <Link to="/attendance" className="col-xl-3 col-sm-6 grid-margin stretch-card text-white">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-9">
                  <div className="d-flex align-items-center align-self-start">
                    <h5 className="mb-0">Attendance</h5>
                  </div>
                </div>
                <div className="col-3">
                  <div className="icon icon-box-success ">
                    <span className="mdi mdi-calendar-today icon-item"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
        <Link to="/markattendance" className="col-xl-3 col-sm-6 grid-margin stretch-card text-white">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-9">
                  <div className="d-flex align-items-center align-self-start">
                    <h5 className="mb-0">Mark Attendance</h5>
                  </div>
                </div>
                <div className="col-3">
                  <div className="icon icon-box-success ">
                    <span className="mdi mdi-calendar-check icon-item"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </Link>
          <Link to="/enquiryform" className="col-xl-3 col-sm-6 grid-margin stretch-card text-white">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-9">
                  <div className="d-flex align-items-center align-self-start">
                    <h5 className="mb-0">Enquiry Form</h5>
                  </div>
                </div>
                <div className="col-3">
                  <div className="icon icon-box-success ">
                    <span className="mdi mdi-note icon-item"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </Link>
          <Link to="/invoice" className="col-xl-3 col-sm-6 grid-margin stretch-card text-white">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-9">
                  <div className="d-flex align-items-center align-self-start">
                    <h5 className="mb-0">Fee Receipt</h5>
                  </div>
                </div>
                <div className="col-3">
                  <div className="icon icon-box-success ">
                    <span className="mdi mdi mdi-receipt icon-item"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </Link>
        </div>
    </>
  );
};

export default Dashboard;
