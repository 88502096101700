import "./App.css";
import "./styles/css/style.css";
import "./styles/vendors/mdi/css/materialdesignicons.min.css";
import "./styles/vendors/css/vendor.bundle.base.css";
import "./styles/vendors/select2/select2.min.css";
import "./styles/vendors/select2-bootstrap-theme/select2-bootstrap.min.css";
import "./styles/vendors/jvectormap/jquery-jvectormap.css";
import "./styles/vendors/flag-icon-css/css/flag-icon.min.css";
import "./styles/vendors/owl-carousel-2/owl.carousel.min.css";
import "./styles/vendors/owl-carousel-2/owl.theme.default.min.css";
import "./styles/images/favicon.png";
import { Routes, Route } from "react-router-dom";
import LoginComponent from "./components/LoginComponent";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MainPanel from "./components/MainPanel";
import StudentPanel from "./components/StudentPanel";
import { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";


function App() {
  const [userRole, setUserRole] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('erptoken');
    if (token) {
      const decoded = jwt_decode(token);
      if (decoded.isAdmin === "True") {
        setUserRole(true);
      }
      else{
        setUserRole(false);
      }
    }
  }, []);


  return (
    <>
      <Routes>
        <Route element={<ProtectedRoutes />}>
        {userRole ? 
          <Route path="*" element={<MainPanel />} />
          :
          <Route path="*" element={<StudentPanel />} />
        }

        </Route>
        <Route path="/login" element={<LoginComponent handleUserRole={setUserRole}/>} />
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
