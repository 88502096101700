import React, {useState} from "react";
import Navbar from "./Navbar";
import Sidebar from "./SideBar";
import { Routes, Route } from "react-router-dom";
import ProtectedRoutes from "../utils/ProtectedRoutes";
import Dashboard from "./Dashboard";
import AddStudent from "./AddStudent";
import AddTeacher from "./AddTeacher";
import BoardAndClass from "./BoardAndClass";
import ClassTest from "./ClassTest";
import StudentMarks from "./StudentMarks";
import Attendance from "./Attendance";
import MarkAttendance from "./MarkAttendance";
import EnquiryForm from "./EnquiryForm";
import Invoice from "./Invoice";
import PageNotFound from "./PageNotFound";
import InvoiceReceipt from "./InvoiceReceipt";

const MainPanel = () => {

  const [togglesidebarmobile , settogglesidebarmobile] = useState(false);


  return (
    <div className="container-scroller">
      <Sidebar togglesidebarmobile={togglesidebarmobile}/>
      <div className="container-fluid page-body-wrapper">
        <Navbar settogglesidebarmobile={settogglesidebarmobile} togglesidebarmobile={togglesidebarmobile}/>
        <div className="main-panel">
          <div className="content-wrapper">
            <Routes>
                <Route element={<ProtectedRoutes />}>
                <Route exact path="/" element={<Dashboard />} />
                <Route path="/addteacher" element={<AddTeacher />} />
                <Route path="/addstudent" element={<AddStudent />} />
                <Route path="/boardandclass" element={<BoardAndClass />} />
                <Route path="/classtest" element={<ClassTest />} />
                <Route path="/studentmarks" element={<StudentMarks />} />
                <Route path="/attendance" element={<Attendance />} />
                <Route path="/markattendance" element={<MarkAttendance />} />
                <Route path="/enquiryform" element={<EnquiryForm />} />
                <Route path="/invoice" element={<Invoice />} />
                <Route path="/invoice/:id" element={<InvoiceReceipt />} />
                <Route path="*" element={<PageNotFound />} />
                </Route>
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPanel;
