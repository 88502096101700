import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoutes = () => {
  const token = localStorage.getItem('erptoken');
  const auth = { token: Boolean(token) };
  
    return (
        auth.token ? <Outlet/> : <Navigate to='/login'/>
      )
}

export default ProtectedRoutes