import React, {useState} from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { CircleSpinnerOverlay } from 'react-spinner-overlay'
import { BASE_URL } from '../constants';
import jwt_decode from "jwt-decode";



const LoginComponent = (props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errormsg, setError] = useState('');
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

    const onhandleSubmit = async (event) => {
      event.preventDefault();
      try {
        const baseURL = BASE_URL;
        setLoading(true);
        const response = await axios.post(`${baseURL}/api/Auth/Login`, { username , password });
        const data = response.data;
        setLoading(false);
        if(data.success === true){
          localStorage.setItem('erptoken', data.token);
          localStorage.setItem('erprefreshtoken', data.refreshToken);
          const decoded = jwt_decode(data.token);
          if (decoded.isAdmin === "True") {
            props.handleUserRole(true);
          }
          else{
            props.handleUserRole(false);
          }
          setUsername("");
          setPassword("");
          toast.success('Login successful!');
          navigate('/');
        }
        else{
          toast.error(data.errorMessage);
        }
      } catch (error) {
        setError(error.message);
        setLoading(false);
        toast.error(errormsg);
      }
    };


  return (
    <>
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="row w-100 m-0">
          <div className="content-wrapper full-page-wrapper d-flex align-items-center auth login-bg">
            <div className="card col-lg-4 mx-auto">
              <div className="card-body px-5 py-5">
                <h3 className="card-title text-left mb-3">Login</h3>
                <form onSubmit={onhandleSubmit}>
                  <div className="form-group">
                    <label>Username or email *</label>
                    <input type="text" className="form-control p_input" value={username} onChange={(event) => setUsername(event.target.value)} />
                  </div>
                  <div className="form-group">
                    <label>Password *</label>
                    <input type="password" className="form-control p_input" value={password} onChange={(event) => setPassword(event.target.value)}/>
                  </div>
                  {/* <div className="form-group d-flex align-items-center justify-content-between">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input type="checkbox" className="form-check-input" /> Remember me </label>
                    </div>
                    <a href="#" className="forgot-pass">Forgot password</a>
                  </div> */}
                  <div className="text-center mt-5">
                    <button type="submit" className="btn btn-primary btn-block enter-btn">Login</button>
                  </div>
                  {/* <div className="d-flex">
                    <button className="btn btn-facebook mr-2 col">
                      <i className="mdi mdi-facebook"></i> Facebook </button>
                    <button className="btn btn-google col">
                      <i className="mdi mdi-google-plus"></i> Google plus </button>
                  </div>
                  <p className="sign-up">Don't have an Account?<a href="/"> Sign Up</a></p> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CircleSpinnerOverlay
        loading={loading} 
        overlayColor="rgba(0,153,255,0.2)"
      />
    </>
  )
}

export default LoginComponent