import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import AddStandards from "./AddStandards";
import { BASE_URL } from '../constants';

const BoardAndClass = () => {
  const [boardlist, setBoardList] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [EditId, setEditId] = useState(null);
  const [error , setError] = useState(null);
  const [ boardForm , setBoardForm ] = useState({
    "name" : "",
    "sortOrder" : ""
  });

  

  const baseURL = BASE_URL; 
  const token = localStorage.getItem('erptoken');
  const deletemodal = document.querySelector("#deletemodal");
  const editmodal = document.querySelector("#editboards");
  

  const handleSetForm = (event) => {
    setBoardForm({ ...boardForm , [event.target.name] : event.target.value });
  }


  const handleSubmit = async (event) =>{
    event.preventDefault();
    if(boardForm.sortOrder === ""){
      boardForm.sortOrder = 0;
    }
    try {
      const response = await axios.post(`${baseURL}/api/Boards`, boardForm ,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setBoardList(boardlist => [...boardlist, {id: response.data.id, name: response.data.name, sortOrder: response.data.sortOrder}].sort((a, b) => a.sortOrder - b.sortOrder));
      setBoardForm({
      "name" : "",
      "sortOrder" : ""  
      });
      const modal = document.querySelector("#addboards");
      modal.style.display = "none";
      toast.success("Board Has been Added Successfully")
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  }


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/Boards`,{
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
        setBoardList(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);


  const handleDeleteModal = (key) => { 
    setDeleteId(key);
    deletemodal.style.display = "block";
  }

  const handleDelete = () => {
    axios.delete(`${baseURL}/api/Boards/${deleteId}`,{
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    .then(response => {
      console.log(response.data);
      toast.success("Board has been Successfully Deleted !");
      setBoardList(boardlist.filter((board) => board.id !== deleteId));
      setDeleteId(null);
      deletemodal.style.display = "none";
    })
    .catch(error => {
      console.error(error);
      toast.success(error);
      setDeleteId(null);
      deletemodal.style.display = "none";
    });
  }


  const handleEditModal = (key) =>{
    setEditId(key);
    const editboard = boardlist.filter(a => a.id === key);
    setBoardForm({
      name: editboard[0].name,
      sortOrder: editboard[0].sortOrder,
    });

    editmodal.style.display = "block";

  }

  const handleEditForm = (event) => {
    setBoardForm({ ...boardForm , [event.target.name] : event.target.value });
  }

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    if(boardForm.sortOrder === ""){
      boardForm.sortOrder = 0;
    }
    try {
      const response = await axios.put(`${baseURL}/api/Boards/${EditId}`, boardForm ,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const updatedBoardList = boardlist.map(board => {
        if (board.id === response.data.id) {
          return response.data;
        }
        return board;
      });
      setBoardList(updatedBoardList.sort((a, b) => a.sortOrder - b.sortOrder));
      setBoardForm({
      "name" : "",
      "sortOrder" : ""  
      });
      editmodal.style.display = "none";
      toast.success("Board Has been Successfully Edited")
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  }



  return (
    <>
      <div className="page-header">
        <h3 className="page-title">Boards And Standards</h3>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center pb-4">
                <h4 className="card-title m-0">Boards</h4>
                <a href="javascript:void(0)" className="btn btn-info" onClick={()=> {
                  const modal = document.querySelector("#addboards");
                  modal.style.display = "block";
                }}>
                  <span className="mdi mdi-plus mr-1"></span>
                  <span>Add Board</span>
                </a>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Boards</th>
                      <th>Order</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                  {boardlist.map((data) => (
                    <tr key={data.id}>
                      <td>{data.name}</td>
                      <td>{data.sortOrder}</td>
                      <td>
                        <a
                          href="javascript:void(0)"
                          onClick={() => handleDeleteModal(data.id)}
                          className="mdi mdi-trash-can text-danger display-5"
                        ></a>
                        <a
                          href="javascript:void(0)"
                          onClick={() =>handleEditModal(data.id)}
                          className="mdi mdi-pencil text-info display-5 ml-3"
                        ></a>
                      </td>
                    </tr>
                     ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <AddStandards />
      </div>

     

      {/* Bootstrap Modal */}

      <div className="modal" id="addboards" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add School Board</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={handleSubmit}>
            <div className="modal-body">
          <div className="form-group">
            <label htmlFor="name">Board</label>
            <input type="text" className="form-control" required name="name" value={boardForm.name} onChange={handleSetForm} placeholder="Enter School Board"  />
          </div>
          <div className="form-group">
            <label htmlFor="sortOrder">Order</label>
            <input type="text" className="form-control" name="sortOrder" value={boardForm.sortOrder} onChange={handleSetForm} placeholder="Enter Sort Order"  />
          </div>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                Add Board 
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={()=>{
                  const modal = document.querySelector("#addboards");
                  modal.style.display = "none";
                }}
              >
                Close
              </button>
            </div>
            </form>
          </div>
        </div>
      </div>

      {/* Edit Modal */}

      <div className="modal" id="editboards" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit School Board</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={handleEditSubmit}>
            <div className="modal-body">
          <div className="form-group">
            <label htmlFor="name">Board</label>
            <input type="text" className="form-control" required name="name" value={boardForm.name} onChange={handleEditForm} placeholder="Enter School Board"  />
          </div>
          <div className="form-group">
            <label htmlFor="sortOrder">Order</label>
            <input type="text" className="form-control" name="sortOrder" value={boardForm.sortOrder} onChange={handleEditForm} placeholder="Enter Sort Order"  />
          </div>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                Edit Board 
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={()=>{
                  const modal = document.querySelector("#editboards");
                  modal.style.display = "none";
                }}
              >
                Close
              </button>
            </div>
            </form>
          </div>
        </div>
      </div>

      <div className="modal" id="deletemodal" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content bg-white">
            <div className="modal-header d-flex justify-content-around align-items-center">
              <h1 className="modal-title text-danger"><span className="mdi mdi-close-circle-outline" style={{fontSize:"50px"}}></span></h1>
              <div className="text-danger text-center">
              <p style={{fontSize:"22px"}}>Are you sure you want to Delete?</p>
            </div>
            </div>
            
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"  onClick={()=>{
                  const modal = document.querySelector("#deletemodal");
                  modal.style.display = "none";
                }}>Close</button>
              <button type="button" className="btn btn-danger" onClick={handleDelete}>Confirm</button>
            </div>
          </div>
        </div>
      </div>
   
    </>
  );
};

export default BoardAndClass;
