import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import DeleteModal from "./DeleteModal";
import axios from "axios";
import MarkAttendance from "./MarkAttendance";
import { BASE_URL } from '../constants';

const Attendance = () => {
    const attmodal = document.querySelector("#attmodal");
    const [AttForm, setAttForm] = useState({
        boardId: "",
        standardId: "",
        date: "",
        startTime: "",
        endTime: "",
      });
      const [boardslist, setboardslist] = useState([]);
      const [standardlist, setStandardList] = useState([]);
      const [editmode, setEditMode] = useState(false);
      const [attendancelist, setAttendanceList] = useState([]);
      const [eid, setEid] = useState(null);
      const [togglemodal , setToggleModal] = useState(false);

      const baseURL = BASE_URL;
      const token = localStorage.getItem("erptoken");

      const handleBoards = async () => {
        try {
          const response = await axios.get(`${baseURL}/api/Students/FetchBoards`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setboardslist(response.data);
        } catch (error) {
          console.error(error);
          toast.error(error);
        }
      };
    
      const handleStandards = async () => {
        try {
          const response = await axios.get(
            `${baseURL}/api/Students/FetchStandards/${AttForm.boardId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setStandardList(response.data);
        } catch (error) {
          console.error(error);
          toast.error(error);
        }
      }; 

      const handleBoardId = (e) => {
        setAttForm((AttForm) => ({
          ...AttForm,
          boardId: e.target.value,
        }));
      };

      const handleSetForm = (event) => {
        setAttForm({ ...AttForm, [event.target.name]: event.target.value });
      };

      const handleCreateAttendance = async (event) => {
        event.preventDefault();
    
        try {
          const response = await axios.post(`${baseURL}/api/Attendances`, AttForm, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const dateStr = response.data.date;
          const starttimeStr = response.data.startTime;
          const endtimeStr = response.data.endTime;
          const date = new Date(dateStr);
          const starttime = new Date(starttimeStr);
          const endtime = new Date(endtimeStr);
          const dateString = date
            .toLocaleString("en-IN", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
            .replace(/ /g, "-");
          const starttimeString = starttime.toLocaleString("en-IN", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });
          const endtimeString = endtime.toLocaleString("en-IN", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });
          const formattedData = {
            ...response.data,
            date: dateString,
            startTime: starttimeString,
            endTime: endtimeString,
          };
          setAttendanceList([...attendancelist, formattedData]);
    
          setAttForm({
            boardId: "",
            standardId: "",
            date: "",
            startTime: "",
            endTime: "",
          });
          attmodal.style.display = "none";
          toast.success("Attendance has been Created Successfully");
          console.log(response.data);
        } catch (error) {
          console.error(error);
          toast.error(error);
        }
      };


      useEffect(() => {
        const fetchAttendanceData = async () => {
          try {
            const response = await axios.get(`${baseURL}/api/Attendances`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            const updatedAttList = response.data.map((att) => {
              const dateStr = att.date;
              const starttimeStr = att.startTime;
              const endtimeStr = att.endTime;
              const date = new Date(dateStr);
              const starttime = new Date(starttimeStr);
              const endtime = new Date(endtimeStr);
              const dateString = date
                .toLocaleString("en-IN", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })
                .replace(/ /g, "-");
              const starttimeString = starttime.toLocaleString("en-IN", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              });
              const endtimeString = endtime.toLocaleString("en-IN", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              });
              return {
                ...att,
                date: dateString,
                startTime: starttimeString,
                endTime: endtimeString,
              };
            });
            setAttendanceList(updatedAttList);
          } catch (error) {
            console.error(error);
          }
        };
        fetchAttendanceData();

        const fetchboards = async () => {
          try {
            const response = await axios.get(`${baseURL}/api/Students/FetchBoards`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            setboardslist(response.data);
          } catch (error) {
            console.error(error);
            toast.error(error);
          }
        };
        fetchboards();
    
      }, []);
    

      const handleDeleteModal = (key) => {
        setEid(key);
        setToggleModal(true);
      };

      const handleDelete = () => {
        axios.delete(`${baseURL}/api/Attendances/${eid}`,{
          headers: {
            Authorization: `Bearer ${token}`,
          }
        })
        .then(response => {
          toast.success("Attendance has been Successfully Deleted !");
          setAttendanceList(attendancelist.filter((a) => a.id !== eid));
          setEid(null);
          setToggleModal(false);
        })
        .catch(error => {
          console.error(error);
          toast.success(error);
          setEid(null);
          setToggleModal(false);
        });
      }

      const handleEditModal = (key) => {
        setEditMode(true);
        setEid(key);
        const editdata = attendancelist.filter(a => a.id === key);
        const date = new Date(editdata[0].date);
        const formattedDate = date.toISOString().substr(0, 10);
        const starttimeParts = editdata[0].startTime.split(' ');
        let starthour = parseInt(starttimeParts[0].split(':')[0]);
        const startminute = parseInt(starttimeParts[0].split(':')[1]);
        if (starttimeParts[1].toLowerCase() === 'pm') {
            starthour += 12;
        }
        const formattedstartTime = `${starthour.toString().padStart(2, '0')}:${startminute.toString().padStart(2, '0')}`;
        const endtimeParts = editdata[0].endTime.split(' ');
        let endhour = parseInt(endtimeParts[0].split(':')[0]);
        const endminute = parseInt(endtimeParts[0].split(':')[1]);
        if (endtimeParts[1].toLowerCase() === 'pm') {
            endhour += 12;
        }
        const formattedendTime = `${endhour.toString().padStart(2, '0')}:${endminute.toString().padStart(2, '0')}`;
        setAttForm({
            boardId: editdata[0].boardId,
            standardId: editdata[0].standardId,
            date: formattedDate,
            startTime: formattedstartTime,
            endTime: formattedendTime,
          });
    
          attmodal.style.display = "block";
      };

      const handleEditAttendance = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.put(`${baseURL}/api/Attendances/${eid}`, AttForm ,{
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
    
            const updatedAttList = attendancelist.map(att => {
              if (att.id === response.data[0].id) {
                return response.data[0];
              }
              return att;
            });
    
            setAttendanceList(updatedAttList);
    
            setAttForm({
                boardId: "",
                standardId: "",
                date: "",
                startTime: "",
                endTime: "",
              });
    
            setEid(null);
            attmodal.style.display = "none";
            toast.success("Attendance Has been Successfully Edited")
          } catch (error) {
            console.error(error);
            toast.error(error);
          }
      };

      console.log(AttForm);

  return (
    <>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center pb-4">
                <h4 className="card-title m-0">Add Attendance</h4>
                <a
                  href="javascript:void(0)"
                  className="btn btn-info"
                  onClick={() => {
                    const addattmodal =
                      document.querySelector("#attmodal");
                      addattmodal.style.display = "block";
                    setEditMode(false);
                    setAttForm({
                        boardId: "",
                        standardId: "",
                        date: "",
                        startTime: "",
                        endTime: "",
                      });
                  }}
                >
                  <span className="mdi mdi-plus mr-1"></span>
                  <span>Create Test</span>
                </a>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Board</th>
                      <th>Standard</th>
                      <th>Date</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody> 
                  {attendancelist.map((data) => (
                      <tr key={data.id}>
                        <td>{data.board.name}</td>
                        <td>{data.standard.name}</td>
                        <td>{data.date}</td>
                        <td>{data.startTime}</td>
                        <td>{data.endTime}</td>
                        <td>
                          <a
                          href="javascript:void(0)"
                          onClick={() => handleDeleteModal(data.id)}
                          className="mdi mdi-trash-can text-danger display-5"
                        ></a>
                        <a
                        href="javascript:void(0)"
                        onClick={() => handleEditModal(data.id)}
                        className="mdi mdi-pencil text-info display-5 ml-3"
                      ></a>
                        </td>
                      </tr>
                    ))}           
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add Test Modal */}

      <div className="modal" id="attmodal" tabIndex="-1" role="dialog">
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{editmode ? 'Edit Attendance' : 'Create Attendance'}</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={editmode ? handleEditAttendance : handleCreateAttendance}>
              <div className="modal-body">
                <div className="row">
                  <div className="form-group col-md-6">
                    <label className="col-form-label">Board</label>
                    <div>
                      <select
                        className="form-control"
                        required
                        value={AttForm.boardId}
                        onKeyDown={handleBoards}
                        onClick={handleBoards}
                        onChange={handleBoardId}
                      >
                        <option value="">--Select Board--</option>
                        {boardslist.map((data) => (
                          <option key={data.id} value={data.id} selected={data.id === AttForm.boardId}>
                            {data.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="col-form-label">Standard</label>
                    <div>
                      <select
                        className="form-control"
                        required
                        name="standardId"
                        value={AttForm.standardId}
                        onKeyDown={handleStandards}
                        onClick={handleStandards}
                        onChange={handleSetForm}
                      >
                        <option value="">--Select Standard--</option>
                        {standardlist.map((data) => (
                          <option key={data.id} value={data.id} selected={data.id === AttForm.standardId}>
                            {data.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="date">Date</label>
                  <input
                    type="date"
                    required
                    className="form-control"
                    name="date"
                    placeholder="Enter Test Date"
                    onChange={handleSetForm}
                    value={AttForm.date}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="time">Start Time</label>
                  <input
                    type="time"
                    required
                    className="form-control"
                    name="startTime"
                    placeholder="Enter Test time"
                    onChange={handleSetForm}
                    value={AttForm.startTime}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="time">End Time</label>
                  <input
                    type="time"
                    required
                    className="form-control"
                    name="endTime"
                    placeholder="Enter Test time"
                    onChange={handleSetForm}
                    value={AttForm.endTime}
                  />
                </div>

              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-primary">
                  {editmode ? 'Edit Attendance' : 'Create Attendance'}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => {
                    const addattmodal =
                      document.querySelector("#attmodal");
                      addattmodal.style.display = "none";
                      setAttForm({
                        boardId: "",
                        standardId: "",
                        date: "",
                        startTime: "",
                        endTime: "",
                      });
                  }}
                  >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <DeleteModal toggle={togglemodal} togglehandle={setToggleModal} handleDelete={handleDelete}/>
    </>
  )
}

export default Attendance