import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import DeleteModal from "./DeleteModal";
import { BASE_URL } from '../constants';

const ClassTest = () => {
  const [TestForm, setTestForm] = useState({
    testName: "",
    boardId: "",
    standardId: "",
    date: "",
    time: "",
    outOfMarks: "",
    description: "",
  });
  const [boardslist, setboardslist] = useState([]);
  const [standardlist, setStandardList] = useState([]);
  const [testlist, setTestList] = useState([]);
  const [eid, setEid] = useState(null);
  const [togglemodal , setToggleModal] = useState(false);
  const [editmode, setEditMode] = useState(false);

  const addtestmodal = document.querySelector("#addtestmodal");
  const baseURL = BASE_URL;
  const token = localStorage.getItem("erptoken");

  const handleBoards = async () => {
    try {
      const response = await axios.get(`${baseURL}/api/Students/FetchBoards`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setboardslist(response.data);
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  };

  const handleStandards = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/api/Students/FetchStandards/${TestForm.boardId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setStandardList(response.data);
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  };

  const handleBoardId = (e) => {
    setTestForm((TestForm) => ({
      ...TestForm,
      boardId: e.target.value,
    }));
  };


  const handleSetForm = (event) => {
    setTestForm({ ...TestForm, [event.target.name]: event.target.value });
  };

  const handleSubmitTest = async (event) => {
    event.preventDefault();

    TestForm.outOfMarks = TestForm.outOfMarks || 0;

    try {
      const response = await axios.post(`${baseURL}/api/Tests`, TestForm, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const dateStr = response.data.date;
      const timeStr = response.data.time;
      const date = new Date(dateStr);
      const time = new Date(timeStr);
      const dateString = date
        .toLocaleString("en-IN", {
          day: "numeric",
          month: "short",
          year: "numeric",
        })
        .replace(/ /g, "-");
      const timeString = time.toLocaleString("en-IN", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      const formattedData = {
        ...response.data,
        date: dateString,
        time: timeString,
      };
      setTestList([...testlist, formattedData]);

      setTestForm({
        testName: "",
        boardId: "",
        standardId: "",
        date: "",
        time: "",
        outOfMarks: "",
        description: "",
      });
      addtestmodal.style.display = "none";
      toast.success("Test has been Created Successfully");
      console.log(response.data);
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  };

  useEffect(() => {
    const baseURL = BASE_URL;
  const token = localStorage.getItem("erptoken");

    const fetchTestData = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/tests`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const updatedTestList = response.data.map((test) => {
          const dateStr = test.date;
          const timeStr = test.time;
          const date = new Date(dateStr);
          const time = new Date(timeStr);
          const dateString = date
            .toLocaleString("en-IN", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
            .replace(/ /g, "-");
          const timeString = time.toLocaleString("en-IN", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });
          return {
            ...test,
            date: dateString,
            time: timeString,
          };
        });
        setTestList(updatedTestList);
      } catch (error) {
        console.error(error);
      }
    };
    fetchTestData();

    const fetchboards = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/Students/FetchBoards`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setboardslist(response.data);
      } catch (error) {
        console.error(error);
        toast.error(error);
      }
    };
    fetchboards();


  }, []);


  const handleDeleteModal = (key) => {
    setEid(key);
    setToggleModal(true);
  };

  const handleDelete = () => {
    axios.delete(`${baseURL}/api/Tests/${eid}`,{
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    .then(response => {
      toast.success("Test has been Successfully Deleted !");
      setTestList(testlist.filter((a) => a.id !== eid));
      setEid(null);
      setToggleModal(false);
    })
    .catch(error => {
      console.error(error);
      toast.success(error);
      setEid(null);
      setToggleModal(false);
    });
  }

  const handleEditModal = (key) => {
    setEditMode(true);
    setEid(key);
    const editdata = testlist.filter(a => a.id === key);
    const date = new Date(editdata[0].date);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = date.toLocaleDateString('en-IN', options).split('/').reverse().join('-');
    const timeParts = editdata[0].time.split(' ');
    let hour = parseInt(timeParts[0].split(':')[0]);
    const minute = parseInt(timeParts[0].split(':')[1]);
    if (timeParts[1].toLowerCase() === 'pm') {
        hour += 12;
    }
    const formattedTime = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
    setTestForm({
        testName: editdata[0].testName,
        boardId: editdata[0].boardId,
        standardId: editdata[0].standardId,
        date: formattedDate,
        time: formattedTime,
        outOfMarks: editdata[0].outOfMarks,
        description: editdata[0].description,
      });

      addtestmodal.style.display = "block";
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    TestForm.outOfMarks = TestForm.outOfMarks || 0;

    try {
        const response = await axios.put(`${baseURL}/api/Tests/${eid}`, TestForm ,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const updatedTestList = testlist.map(test => {
          if (test.id === response.data[0].id) {
            return response.data[0];
          }
          return test;
        });

        setTestList(updatedTestList);

        setTestForm({
            testName: "",
            boardId: "",
            standardId: "",
            date: "",
            time: "",
            outOfMarks: "",
            description: "",
          });

        setEid(null);
        addtestmodal.style.display = "none";
        toast.success("Test Has been Successfully Edited")
      } catch (error) {
        console.error(error);
        toast.error(error);
      }
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center pb-4">
                <h4 className="card-title m-0">Class Test</h4>
                <a
                  href="javascript:void(0)"
                  className="btn btn-info"
                  onClick={() => {
                    const addtestmodal =
                      document.querySelector("#addtestmodal");
                    addtestmodal.style.display = "block";
                    setEditMode(false);
                    setTestForm({
                        testName: "",
                        boardId: "",
                        standardId: "",
                        date: "",
                        time: "",
                        outOfMarks: "",
                        description: "",
                      });
                  }}
                >
                  <span className="mdi mdi-plus mr-1"></span>
                  <span>Create Test</span>
                </a>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Subject</th>
                      <th>Board</th>
                      <th>Standard</th>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Out of Marks</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {testlist.map((data) => (
                      <tr key={data.id}>
                        <td>{data.testName}</td>
                        <td>{data.board.name}</td>
                        <td>{data.standard.name}</td>
                        <td>{data.date}</td>
                        <td>{data.time}</td>
                        <td>{data.outOfMarks}</td>
                        <td>
                          <a
                          href="javascript:void(0)"
                          onClick={() => handleDeleteModal(data.id)}
                          className="mdi mdi-trash-can text-danger display-5"
                        ></a>
                        <a
                        href="javascript:void(0)"
                        onClick={() => handleEditModal(data.id)}
                        className="mdi mdi-pencil text-info display-5 ml-3"
                      ></a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add Test Modal */}

      <div className="modal" id="addtestmodal" tabIndex="-1" role="dialog">
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{editmode ? 'Edit Test' : 'Create Test'}</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={editmode ? handleEditSubmit : handleSubmitTest}>
              <div className="modal-body">
                <div className="form-group">
                  <label htmlFor="name">Subject</label>
                  <input
                    type="text"
                    className="form-control"
                    name="testName"
                    required
                    placeholder="Enter Subject"
                    onChange={handleSetForm}
                    value={TestForm.testName}
                  />
                </div>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label className="col-form-label">Board</label>
                    <div>
                      <select
                        className="form-control"
                        required
                        value={TestForm.boardId}
                        onKeyDown={handleBoards}
                        onClick={handleBoards}
                        onChange={handleBoardId}
                      >
                        <option value="">--Select Board--</option>
                        {boardslist.map((data) => (
                          <option key={data.id} value={data.id} selected={data.id === TestForm.boardId}>
                            {data.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="col-form-label">Standard</label>
                    <div>
                      <select
                        className="form-control"
                        required
                        name="standardId"
                        value={TestForm.standardId}
                        onKeyDown={handleStandards}
                        onClick={handleStandards}
                        onChange={handleSetForm}
                      >
                        <option value="">--Select Standard--</option>
                        {standardlist.map((data) => (
                          <option key={data.id} value={data.id} selected={data.id === TestForm.standardId}>
                            {data.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="date">Test Date</label>
                  <input
                    type="date"
                    required
                    className="form-control"
                    name="date"
                    placeholder="Enter Test Date"
                    onChange={handleSetForm}
                    value={TestForm.date}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="time">Test Time</label>
                  <input
                    type="time"
                    required
                    className="form-control"
                    name="time"
                    placeholder="Enter Test time"
                    onChange={handleSetForm}
                    value={TestForm.time}
                  />
                </div>
                
                <div className="form-group">
                  <label htmlFor="time">Test Marks</label>
                  <input
                    type="text"
                    className="form-control"
                    name="outOfMarks"
                    placeholder="Enter Test Marks"
                    onChange={handleSetForm}
                    onKeyPress={(event) => {
                      const keyCode = event.which || event.keyCode;
                      const keyValue = String.fromCharCode(keyCode);
                      if (!/^\d+$/.test(keyValue)) {
                        event.preventDefault();
                      }
                    }}
                    value={TestForm.outOfMarks}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="description">Note</label>
                  <input
                    type="text"
                    className="form-control"
                    name="description"
                    placeholder="Add Note"
                    onChange={handleSetForm}
                    value={TestForm.description}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-primary">
                  {editmode ? 'Edit Test' : 'Create Test'}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => {
                    const addtestmodal =
                      document.querySelector("#addtestmodal");
                    addtestmodal.style.display = "none";
                    setTestForm({
                        testName: "",
                        boardId: "",
                        standardId: "",
                        date: "",
                        time: "",
                        outOfMarks: "",
                        description: "",
                      });
                  }}
                  
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>


      <DeleteModal toggle={togglemodal} togglehandle={setToggleModal} handleDelete={handleDelete}/>
    </>
  );
};

export default ClassTest;
