import React , {useEffect , useState }from "react";
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import { BASE_URL } from '../constants';
import axios from "axios";

const StudentAttendance = () => {

    const baseURL = BASE_URL;
    const token = localStorage.getItem("erptoken");
    const refreshToken = localStorage.getItem('erprefreshtoken');
    const [attendanceData, setAttendanceData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(`${baseURL}/api/StudentPanel/FetchStudentAttendance/${refreshToken}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            setAttendanceData(response.data);
          } catch (error) {
            console.error(error);
          }
        };
        fetchData();
      }, []);

    //   const customDates = attendanceData.map((attendance) => new Date(attendance.attendance.date));

      const tileClassName = ({ date, view }) => {
          for (let i = 0; i < attendanceData.length; i++) {
            const customdate = new Date(attendanceData[i].attendance.date);
            const dateString = customdate.toISOString().slice(0, 10);
            const isPresent = attendanceData[i].isPresent;
            if (date.toISOString().slice(0, 10) === dateString) {
                if(isPresent){
                return 'present';
                }
                return 'absent';
            }
          }
        
      };

  return (
    <>
     <Calendar className="mx-auto" tileClassName={tileClassName}/>
    </>
  );
};

export default StudentAttendance;
