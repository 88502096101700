import React , {useState , useEffect} from 'react';
import { Formik, Form, Field, ErrorMessage , resetForm } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from 'react-toastify';
import { BASE_URL } from '../constants';
import DeleteModal from "./DeleteModal";

const EnquiryForm = () => {

    const [enquiryList , setEnquiryList] = useState([]);
    const [togglemodal , setToggleModal] = useState(false);
    const [enquiryId, setEnquiryId] = useState(null);
    const [editmode, setEditMode] = useState(false);
    const [initialValues , setInitialValues] = useState({
        studentName: "",
        mobileNumber: "",
        board: "",
        standard: "",
        area: "",
        address: "",
      });
    const [initialValuesVersion, setInitialValuesVersion] = useState(0);
    const baseURL = BASE_URL; 
    const token = localStorage.getItem('erptoken');

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(`${baseURL}/api/EnquiryForms`,{
              headers: {
                Authorization: `Bearer ${token}`,
              }
            });
            setEnquiryList(response.data);
          } catch (error) {
            console.error(error);
          }
        };
        fetchData();
      },[baseURL, token]);


    const validationSchema = Yup.object({
        studentName: Yup.string().required("Student Name is Required"),
        mobileNumber: Yup.string() 
        .required('Mobile number is required')
        .matches(/^[0-9]{10}$/, 'Mobile number must be a valid 10-digit number'),
      });
      

      const onSubmit = async (values , { resetForm } ) => {
            try {
                const response = await axios.post(`${baseURL}/api/EnquiryForms`, values ,{
                    headers: {
                    Authorization: `Bearer ${token}`,
                    },
                });
                setEnquiryList(enquiryList => [...enquiryList, response.data]);
                resetForm({ values: null });
                document.querySelector("#addenquiry").style.display = "none";
                toast.success("Enquiry has been Generated")
            } catch (error) {
                console.error(error);
                toast.error(error);
            }
      };

  
      

      const handleDeleteModal = (key) => {
        setEnquiryId(key);
        setToggleModal(true);
      };
      
      const handleDelete = () => {
        axios.delete(`${baseURL}/api/EnquiryForms/${enquiryId}`,{
          headers: {
            Authorization: `Bearer ${token}`,
          }
        })
        .then(response => {
          toast.success("Enquiry has been Deleted..");
          setEnquiryList(enquiryList.filter((a) => a.id !== enquiryId));
          setEnquiryId(null);
          setToggleModal(false);
        })
        .catch(error => {
          console.error(error);
          toast.success(error);
          setEnquiryId(null);
          setToggleModal(false);
        });
      }

      const handleEditModal = (key) => {
        setEditMode(true);
        setEnquiryId(key);
        document.querySelector("#addenquiry").style.display = "block";
        const editenquiry = enquiryList.filter(a => a.id === key);
        const editedValues = {
            studentName: editenquiry[0].studentName,
            mobileNumber: editenquiry[0].mobileNumber,
            board: editenquiry[0].board,
            standard: editenquiry[0].standard,
            area: editenquiry[0].area,
            address: editenquiry[0].address,
          };
          setInitialValues(editedValues);
          setInitialValuesVersion((v) => v + 1);
      };

      const onEdit = async (values, { resetForm }) => {
        try {
          const response = await axios.put(`${baseURL}/api/EnquiryForms/${enquiryId}`, values ,{
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          const updatedList = enquiryList.map((enquiry) => {
            if (enquiry.id === response.data.id) {
              return response.data;
            } else {
              return enquiry;
            }
          });
      
          setEnquiryList(updatedList);

          resetForm({ values: null });
          setEnquiryId(null);
          document.querySelector("#addenquiry").style.display = "none";
          toast.success("Enquiry Form Changed..")
        } catch (error) {
          console.error(error);
          toast.error(error);
        }
      };
    
  return (
  <>
    <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center pb-4">
              <h4 className="card-title m-0">Enquiry Form</h4>
                <button
                className="btn btn-info"
                onClick={()=> {
                    document.querySelector("#addenquiry").style.display = "block";
                    setEditMode(false);
                  }}
              >
                <span className="mdi mdi-plus mr-1"></span>
                <span>Add Enquiry</span>
              </button>
              
            </div>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Student Name</th>
                    <th>Mobile Number</th>
                    <th>Board</th>
                    <th>Standard</th>
                    <th>Area</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                {enquiryList.map((data) => (
                    <tr key={data.id}>
                      <td>{data.studentName}</td>
                      <td>{data.mobileNumber}</td>
                      <td>{data.board}</td>
                      <td>{data.standard}</td>
                      <td>{data.area}</td>
                      <td>
                       <a
                          href="javascript:void(0)"
                          onClick={() => handleDeleteModal(data.id)}
                          className="mdi mdi-trash-can text-danger display-5"
                        ></a>
                        <a
                          href="javascript:void(0)"
                          onClick={() => handleEditModal(data.id)}
                          className="mdi mdi-pencil text-info display-5 ml-3"
                        ></a>
                      </td>
                    </tr>
                     ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="modal" id="addenquiry" tabIndex="-1" role="dialog">
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{editmode ? 'Edit Enquiry' : 'Add Enquiry'}</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <Formik
             key={initialValuesVersion}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={editmode ? onEdit : onSubmit}
            >
            {(formik) => (
         <Form>
         <div className="modal-body">
           <div className="form-group">
             <Field
               type="text"
               className="form-control"
               name="studentName"
               placeholder="Student Name"
             />
             <div className="form-error">
             <ErrorMessage name="studentName" />
             </div>
             
           </div>
           <div className="row">
             <div className="form-group col-md-6">
               <Field
                 type="text"
                 className="form-control"
                 name="mobileNumber"
                 placeholder="Mobile Number"
               />
               <div className="form-error">
               <ErrorMessage name="mobileNumber" />
               </div>
               
             </div>
             <div className="form-group col-md-6">
               <Field
                 type="text"
                 className="form-control"
                 name="board"
                 placeholder="Board"
               />
               <div className="form-error">
               <ErrorMessage name="board" />
               </div>
             </div>
           </div>
           <div className="form-group">
             <Field
               type="text"
               className="form-control"
               name="standard"
               placeholder="Standard"
             />
              <div className="form-error">
               <ErrorMessage name="standard" />
               </div>
           </div>
           <div className="form-group">
             <Field
               type="text"
               className="form-control"
               name="area"
               placeholder="Area"
             />
              <div className="form-error">
               <ErrorMessage name="area" />
               </div>
           </div>
           <div className="form-group">
             <Field
               type="text"
               className="form-control"
               name="address"
               placeholder="Address"
             />
              <div className="form-error">
               <ErrorMessage name="address" />
               </div>
           </div>
         </div>
         <div className="modal-footer">
           <button type="submit" className="btn btn-primary">
             {editmode ? 'Edit Enquiry' : 'Add Enquiry' }
           </button>
           <button
             type="button"
             className="btn btn-secondary"
             data-dismiss="modal"
             onClick={() => {
               document.querySelector("#addenquiry").style.display = "none";
               setInitialValues({
                studentName: "",
                mobileNumber: "",
                board: "",
                standard: "",
                area: "",
                address: "",
               });
               setInitialValuesVersion(initialValuesVersion + 1);
             }}
           >
             Close
           </button>
         </div>
       </Form>
             )}
             </Formik>
          </div>
        </div>
      </div>

      <DeleteModal toggle={togglemodal} togglehandle={setToggleModal} handleDelete={handleDelete}/>
  </>
  )
}

export default EnquiryForm