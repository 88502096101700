import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import axios from "axios";
import { BASE_URL } from '../constants';

const MarkAttendance = () => {
    const [boardList , setBoardList] = useState([]);
    const [standardList , setStandardList] = useState([]);
    const [attendancelist , setAttendanceList] = useState([]);
    const [timingslot , setTimingSlot] = useState([]);
    const [fetchData , setFetchData] = useState({
        boardId : null,
        standardId : null,
        date : null,
        startTime : null,
        endTime : null,
      });

    const baseURL = BASE_URL; 
    const token = localStorage.getItem('erptoken');
  
    const handlefetchboards = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/Students/FetchBoards`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setBoardList(response.data);
      } catch (error) {
        console.error(error);
        toast.error(error);
      }
    }

    
  const handlefetchstandards = async () => {
    try {
      const response = await axios.get(`${baseURL}/api/Students/FetchStandards/${fetchData.boardId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setStandardList(response.data);
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  };

    const handleSetFetchData = (event) => {
        setFetchData({...fetchData , [event.target.name] : event.target.value})
    }

    const handlefetchattendancelist = async () => {
        try {
            const response = await axios.get(`${baseURL}/api/Attendances/FetchAttendances`, {

              headers: {
                Authorization: `Bearer ${token}`,
              },
              params: {
                "boardId" : fetchData.boardId,
                "standardId" : fetchData.standardId,
                "date" : fetchData.date,
              },
            });
            setTimingSlot(response.data);
          } catch (error) {
            console.error(error);
            toast.error(error);
          }
    }

    const handleSetStudentAttendanceList = async (e) => {
        const filtereddata = timingslot.filter((data) => data.id == e.target.value);
        setFetchData({...fetchData , "startTime" : filtereddata[0].startTime , "endTime" : filtereddata[0].endTime})
        
    }

    const handlegetattendancelist = async () => {
        try {
            const response = await axios.get(`${baseURL}/api/Attendances/FetchStudentAttendances`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              params: {
                "boardId" : fetchData.boardId,
                "standardId" : fetchData.standardId,
                "date" : fetchData.date,
                "startTime" : fetchData.startTime,
                "endTime" : fetchData.endTime
              },
            });
            setAttendanceList(response.data);
          } catch (error) {
            console.error(error);
            toast.error(error);
          }
    }

    const handleMarkPresent = async (e , key) => {
        try {
            const response = await axios.put(`${baseURL}/api/Attendances/MarkAttendance/${key}`, {"isPresent" : e.target.checked} ,{
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            const updatedAttList = attendancelist.map(att => {
                if (att.id === response.data.id) {
                  return response.data;
                }
                return att;
              });
              setAttendanceList(updatedAttList);
        } catch (error) {
            console.error(error);
            toast.error(error);
          }
    };

    console.log(attendancelist);



  return (
  <>
  <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center pb-4">
                <h4 className="card-title m-0">Mark Attendance</h4>
                <div className="d-flex align-items-center">
                <select class="form-control mx-1" name="boardId" value={fetchData.boardId} onClick={handlefetchboards} onKeyDown={handlefetchboards} onChange={handleSetFetchData}>
                    <option value="">--Select Board--</option>
                    {boardList.map((data) => (
                      <option value={data.id} key={data.id}>{data.name}</option>
                    ))}

                  </select>
                  <select class="form-control mx-1" name="standardId" value={fetchData.standardId} onClick={handlefetchstandards} onKeyDown={handlefetchstandards} onChange={handleSetFetchData}>
                    <option value="">--Select Standard--</option>
                    {standardList.map((data) => (
                      <option value={data.id} key={data.id}>{data.name}</option>
                    ))}
                  </select>

                  <input
                    type="date"
                    required
                    className="form-control"
                    name="date"
                    placeholder="Enter Test Date"
                    onChange={(e) => {setFetchData({...fetchData , [e.target.name] : e.target.value})}}
                  />
                  <select class="form-control mx-1" onClick={handlefetchattendancelist} onKeyDown={handlefetchattendancelist} onChange={handleSetStudentAttendanceList}>
                    <option value="">--Select Time--</option>
                    {timingslot.map((data) => (
                        <option value={data.id} key={data.id}>{data.startTime.split('T')[1].substring(0, 5)}-{data.endTime.split('T')[1].substring(0, 5)}</option>
                    ))}
                  </select>
                  <a onClick={handlegetattendancelist} className="btn btn-primary w-100" style={{padding: "11px"}}>Get Attendance</a>
                  </div>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                        <td>Student Name</td>
                        <td>Board</td>
                        <td>Standard</td>
                        <td>Date</td>
                        <td>Present</td>
                    </tr>
                  </thead>
                  <tbody> 
                  {attendancelist && attendancelist.map((data) => (
                    <tr key={data.id}>
                      <td>{data.student.name}</td>
                      <td>{data.attendance.board.name}</td>
                      <td>{data.attendance.standard.name}</td>
                      <td>{new Date(data.attendance.date).toLocaleDateString()}</td>
                      <td>
                        <div class="form-check">
                              <label class="form-check-label">
                                <input type="checkbox" class="form-check-input" checked={data.isPresent} onChange={(e) => {handleMarkPresent(e , data.id)}}/><i class="input-helper"></i></label>
                            </div>
                        </td>
                    </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
  </>
  )
}

export default MarkAttendance