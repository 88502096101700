import React, {useEffect, useState} from 'react';
import { BASE_URL } from '../constants';
import axios from "axios";

const TestsDetails = () => {
    const [testData, setTestData] = useState([]);

  useEffect(() => {
    const baseURL = BASE_URL;
    const token = localStorage.getItem("erptoken");
    const refreshToken = localStorage.getItem('erprefreshtoken');
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/StudentPanel/FetchTestData/${refreshToken}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setTestData(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const currentDate = new Date();
const currentTime = currentDate.getTime();


  return (
    <>
         <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center pb-4">
                <h4 className="card-title m-0">Tests Dates</h4>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                        <th>Subject</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Marks</th>
                        <th>Note</th>
                    </tr>
                  </thead>
                  <tbody>
                    {testData
                    .sort((a, b) => new Date(b.date) - new Date(a.date)) 
                    .filter(data => {
                      const testDate = new Date(data.date);
                      const testTime = new Date(data.time);
                      const propertime = testTime.getTime();
                      return testDate > currentDate && propertime > currentTime;
                    })
                    .map((data) => (
                        <tr key={data.id}>
                            <td>{data.testName}</td>
                            <td>{new Date(data.date).toLocaleDateString('en-IN')}</td>
                            <td>{new Date(data.time).toLocaleTimeString('en-IN' , {hour: '2-digit', minute:'2-digit'})}</td>
                            <td>{data.outOfMarks}</td>
                            <td>{data.description}</td>
                        </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default TestsDetails