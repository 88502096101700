import React from 'react';
import { Link } from "react-router-dom";
import jwt_decode from "jwt-decode";


const StudentSideBar = (props) => {

  const token = localStorage.getItem('erptoken');
  let username;
  if (token) {
    const decoded = jwt_decode(token);
    username = decoded.userName;
  }

  return (
    <>
    <nav className={`sidebar sidebar-offcanvas ${props.togglesidebarmobile ? 'active' : ''}`} id="sidebar">
        <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
        <a className="sidebar-brand brand-logo p-0 px-2 mt-3" href="/"><h2 className='text-white'>EKTA CLASSES</h2></a>
        </div>
        <ul className="nav">
          <li className="nav-item profile">
            <div className="profile-desc">
              <div className="profile-pic">
                <div className="count-indicator">
                  <span className="img-xs rounded-circle mdi mdi-account-circle" style={{fontSize:"32px"}}></span>
                  <span className="count bg-success"></span>
                </div>
                <div className="profile-name">
                  <h5 className="mb-0 font-weight-normal">{username}</h5>
                </div>
              </div>
              {/* <a href="/" id="profile-dropdown" data-toggle="dropdown"><i className="mdi mdi-dots-vertical"></i></a> */}
              {/* <div className="dropdown-menu dropdown-menu-right sidebar-dropdown preview-list" aria-labelledby="profile-dropdown">
                <a href="/" className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi-settings text-primary"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject ellipsis mb-1 text-small">Account settings</p>
                  </div>
                </a>
                <div className="dropdown-divider"></div>
                <a href="/" className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi-onepassword  text-info"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject ellipsis mb-1 text-small">Change Password</p>
                  </div>
                </a>
                <div className="dropdown-divider"></div>
                <a href="/" className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi-calendar-today text-success"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject ellipsis mb-1 text-small">To-do list</p>
                  </div>
                </a>
              </div> */}
            </div>
          </li>
          <li className="nav-item nav-category">
            <span className="nav-link">Navigation</span>
          </li>
          <li className="nav-item menu-items">
            <Link className="nav-link" to="/">
              <span className="menu-icon">
                <i className="mdi mdi-table-large"></i>
              </span>
              <span className="menu-title">Tests</span>
            </Link>
          </li>
          <li className="nav-item menu-items">
            <Link className="nav-link" to="/testmarksdetails">
              <span className="menu-icon">
                <i className="mdi mdi-table-large"></i>
              </span>
              <span className="menu-title">Test Marks</span>
            </Link>
          </li>
          <li className="nav-item menu-items">
            <Link className="nav-link" to="/studentattendance">
              <span className="menu-icon">
                <i className="mdi mdi-table-large"></i>
              </span>
              <span className="menu-title">Student Attendance</span>
            </Link>
          </li>
        </ul>
      </nav>
    </>
  )
}

export default StudentSideBar