import React, { useEffect } from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import { BASE_URL } from '../constants';

const TeacherDetail = (props) => {
  

  const baseURL = BASE_URL;
  const token = localStorage.getItem("erptoken");
  const teacherdeletemodal = document.querySelector("#teacherdelete");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/Teachers`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        props.setTeacher(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);


  const handleDeleteModal = (key) => { 
    props.setTeacherid(key);
    teacherdeletemodal.style.display = "block";
  }

  const handleDelete = () => {
    axios.delete(`${baseURL}/api/Teachers/${props.teacherid}`,{
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    .then(response => {
      console.log(response.data);
      toast.success("Teacher has been Successfully Deleted !");
      props.setTeacher(props.teacherlist.filter((a) => a.id !== props.teacherid));
      props.setTeacherid(null);
      teacherdeletemodal.style.display = "none";
    })
    .catch(error => {
      console.error(error);
      toast.success(error);
      props.setTeacherid(null);
      teacherdeletemodal.style.display = "none";
    });
  }


  const handleEditModal = (key) =>{
    props.setTeacherid(key)
    props.setEditMode(true);
    
    const editteacher = props.teacherlist.filter(a => a.id === key);

    console.log(editteacher);
    props.setTeacherForm({
      name: editteacher[0].name,
      designation: editteacher[0].designation,
      mobileNumber: editteacher[0].mobileNumber,
      emailId: editteacher[0].emailId,
      address: editteacher[0].address,
    });

    props.toggle(true);
  }

  return (
    <>
      <div className="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div className="d-flex justify-content-between align-items-center pb-4">
              <h4 class="card-title">Teacher Details</h4>
              <a href="javascript:void(0)" className="btn btn-info" onClick={()=> {
                  props.toggle(true);
                  props.setEditMode(false);
                }}>
                  <span className="mdi mdi-plus mr-1"></span>
                  <span>Add Teacher</span>
                </a>
              </div>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Designation</th>
                      <th>Mobile Number</th>
                      <th>Email Id</th>
                      <th>Address</th>
                      <th>Resume</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.teacherlist.map((data) => (
                      <tr key={data.id}>
                        <td>{data.name}</td>
                        <td>{data.designation}</td>
                        <td>{data.mobileNumber}</td>
                        <td>{data.emailId}</td>
                        <td>{data.address}</td>
                        <td>
                          <a href={`${baseURL}/${data.resume}`} target="_blank" className={`btn btn-sm btn-info ${data.resume ? '' : 'd-none'}`}>
                            Check Resume
                          </a>
                        </td>
                        <td>
                        <a
                          href="javascript:void(0)"
                          onClick={() => handleEditModal(data.id)}
                          className="mdi mdi-pencil text-info display-5"
                        ></a>
                        <a
                          href="javascript:void(0)"
                          onClick={() => handleDeleteModal(data.id)}
                          className="mdi mdi-trash-can ml-1 text-danger display-5"
                        ></a>
        
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* Delete Modal */}

      <div className="modal" id="teacherdelete" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content bg-white">
            <div className="modal-header d-flex justify-content-around align-items-center">
              <h1 className="modal-title text-danger"><span className="mdi mdi-close-circle-outline" style={{fontSize:"50px"}}></span></h1>
              <div className="text-danger text-center">
              <p style={{fontSize:"22px"}}>Are you sure you want to Delete?</p>
            </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"  onClick={()=>{
                  teacherdeletemodal.style.display = "none";
                }}>Close</button>
              <button type="button" className="btn btn-danger" onClick={handleDelete}>Confirm</button>
            </div>
          </div>
        </div>
      </div>
   

    </>
  );
};

export default TeacherDetail;
