import React from 'react'

const DeleteModal = (props) => {
  return (
    <>
     <div className={`modal ${props.toggle ? 'd-block' : 'd-none'}`} id="delmodal" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content bg-white">
            <div className="modal-header d-flex justify-content-around align-items-center">
              <h1 className="modal-title text-danger"><span className="mdi mdi-close-circle-outline" style={{fontSize:"50px"}}></span></h1>
              <div className="text-danger text-center">
              <p style={{fontSize:"22px"}}>Are you sure you want to Delete?</p>
            </div>
            </div>
            
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"  onClick={()=>{
                 props.togglehandle(false);
                }}>Close</button>
              <button type="button" className="btn btn-danger" onClick={props.handleDelete}>Confirm</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DeleteModal