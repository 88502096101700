import React, {useState} from 'react'
import Navbar from "./Navbar";
import StudentSideBar from './StudentSideBar';
import TestsDetails from './TestsDetails';
import { Routes, Route } from "react-router-dom";
import ProtectedRoutes from "../utils/ProtectedRoutes";
import TestMarksDetails from './TestMarksDetails';
import StudentAttendance from './StudentAttendance';
import PageNotFound from './PageNotFound';

const StudentPanel = () => {
  const [togglesidebarmobile , settogglesidebarmobile] = useState(false);

  return (
    <>
    <div className="container-scroller">
      <StudentSideBar togglesidebarmobile={togglesidebarmobile}/>
      <div className="container-fluid page-body-wrapper">
        <Navbar settogglesidebarmobile={settogglesidebarmobile} togglesidebarmobile={togglesidebarmobile} />
        <div className="main-panel">
          <div className="content-wrapper">
          <Routes>
                <Route element={<ProtectedRoutes />}>
                <Route path="/" element={<TestsDetails />} />
                <Route path="/testmarksdetails" element={<TestMarksDetails />} />
                <Route path="/studentattendance" element={<StudentAttendance />} />
                <Route path="*" element={<PageNotFound />} />
                </Route>
            </Routes>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default StudentPanel