import React, {useEffect, useState} from 'react';
import { BASE_URL } from '../constants';
import axios from "axios";


const TestMarksDetails = () => {
  const [testMarks, setTestMarks] = useState([]);

  useEffect(() => {
    const baseURL = BASE_URL;
    const token = localStorage.getItem("erptoken");
    const refreshToken = localStorage.getItem('erprefreshtoken');
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/StudentPanel/FetchTestMarks/${refreshToken}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setTestMarks(response.data);
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);


  return (
    <>
         <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center pb-4">
                <h4 className="card-title m-0">Tests Dates</h4>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                        <th>Subject</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Marks</th>
                        <th>Out Of Marks</th>
                        <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {testMarks
                        .sort((a, b) => new Date(b.test.date) - new Date(a.test.date)) 
                        .map((data) => (
                        <tr key={data.id}>
                            <td>{data.test.testName}</td>
                            <td>{new Date(data.test.date).toLocaleDateString('en-IN')}</td>
                            <td>{new Date(data.test.time).toLocaleTimeString('en-IN' , {hour: '2-digit', minute:'2-digit'})}</td>
                            <td>{data.marksObtained}</td>
                            <td>{data.test.outOfMarks}</td>
                            {data.marksUpdated ? <td className='text-success'>Updated !</td> : <td className='text-danger'>Not yet Updated !</td> }
                        </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default TestMarksDetails