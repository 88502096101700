import React, { useState  , useEffect} from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import { BASE_URL } from '../constants';


const AddStandards = () => {
  const [boards , setBoards] = useState([{}]);
  const [stddeleteId, setstdDeleteId] = useState(null);
  const [editmode, setEditMode] = useState(false);
  const [StandardForm , setStandardForm] = useState({"name" : "",
  "boardId" : "", 
"sortOrder" : ""});
  const [standardlist, setStandardList] = useState([]);

  const baseURL = BASE_URL;
  const token = localStorage.getItem('erptoken');
  const addstandardmodal = document.querySelector("#addstandards");
  const stddelmodal = document.querySelector("#stddelmodal");

  const handleSelectBoard = async () => {
    try {
      const response = await axios.get(`${baseURL}/api/Standards/FetchBoards`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setBoards(response.data);
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  }

  const handleSetForm = (event) => {
      setStandardForm({ ...StandardForm , [event.target.name] : event.target.value });
  }

  const handleSubmit = async (event) =>{
    event.preventDefault();
    if(StandardForm.sortOrder === ""){
      StandardForm.sortOrder = 0;
    }
    try {
      const response = await axios.post(`${baseURL}/api/Standards`, StandardForm ,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setStandardList(standardlist => [...standardlist, response.data].sort((a, b) => a.sortOrder - b.sortOrder));
      setStandardForm({
        "name" : "",
        "boardId" : "", 
    "sortOrder" : ""
      });
      addstandardmodal.style.display = "none";
      toast.success("Standard Has been Added Successfully")
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  }

  useEffect(() => {
    const baseURL = BASE_URL;
    const token = localStorage.getItem('erptoken');
    const fetchStudentData = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/Standards`,{
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
        setStandardList(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchStudentData();

    const fetchboards = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/Standards/FetchBoards`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setBoards(response.data);
      } catch (error) {
        console.error(error);
        toast.error(error);
      }
    };
    fetchboards();
  }, []);


  const handleDeleteModal = (key) => { 
    setstdDeleteId(key);
    stddelmodal.style.display = "block";
  }

  const handleDelete = () => {
    axios.delete(`${baseURL}/api/Standards/${stddeleteId}`,{
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    .then(response => {
      toast.success("Standard has been Successfully Deleted !");
      setStandardList(standardlist.filter((a) => a.id !== stddeleteId));
      setstdDeleteId(null);
      stddelmodal.style.display = "none";
    })
    .catch(error => {
      console.error(error);
      toast.success(error);
      setstdDeleteId(null);
      stddelmodal.style.display = "none";
    });
  }

    const handlefilterdata = async (event) =>{
      event.preventDefault();
      try {
        const response = await axios.get(`${baseURL}/api/Standards/FilterData/${event.target.value}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setStandardList(response.data);
      } catch (error) {
        console.error(error);
        toast.error(error);
      }
    };

    const handleEditModal = (key) => {
      setEditMode(true);
      setstdDeleteId(key);
      const editstandard = standardlist.filter(a => a.id === key);

      setStandardForm({
        name : editstandard[0].name,
        boardId : editstandard[0].boardId,
        sortOrder : editstandard[0].sortOrder
      });

      addstandardmodal.style.display = "block";
    };



    const handleEdit = async (e) => {
      e.preventDefault();
      if(StandardForm.sortOrder === ""){
        StandardForm.sortOrder = 0;
      }
      try {
        const response = await axios.put(`${baseURL}/api/Standards/${stddeleteId}`, StandardForm ,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const updatedStandardList = standardlist.map(standard => {
          if (standard.id === response.data[0].id) {
            return response.data[0];
          }
          return standard;
        });

        setStandardList(updatedStandardList.sort((a, b) => a.sortOrder - b.sortOrder));

        setStandardForm({
          "name" : "",
          "boardId" : "", 
          "sortOrder" : ""
        });
        setstdDeleteId(null);
        addstandardmodal.style.display = "none";
        toast.success("Standard Has been Successfully Edited")
      } catch (error) {
        console.error(error);
        toast.error(error);
      }
    };



  
  return (
    <>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center pb-4">
              <h4 className="card-title m-0">Standards</h4>
                 <div className="">
                  <select
                    className="form-control"
                    style={{width:"100%"}}
                    name="boardId"
                    onClick={handleSelectBoard}
                    onKeyDown={handleSelectBoard}
                    onChange={handlefilterdata}
                  >
                    <option selected value="">--Select Board--</option>
                    {boards.map(board => (
                      <option key={board.id} value={board.id}>{board.name}</option>
                    ))}
                  </select>
                </div>
                <a
                href="#"
                className="btn btn-info"
                onClick={()=> {
                    addstandardmodal.style.display = "block";
                    setEditMode(false);
                    setStandardForm({
                      "name" : "",
                      "boardId" : "", 
                  "sortOrder" : ""
                    });
                  }}
              >
                <span className="mdi mdi-plus mr-1"></span>
                <span>Add Standard</span>
              </a>
              
            </div>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Standard</th>
                    <th>Board</th>
                    <th>Sort Order</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {standardlist.map((data) => (
                  <tr key={data.id}>
                    <td>{data.name}</td>
                    <td>{data.board.name}</td>
                    <td>{data.sortOrder}</td>
                    <td>
                      <a
                        href="javascript:void(0)"
                        onClick={() => handleDeleteModal(data.id)}
                        className="mdi mdi-trash-can text-danger display-5"
                      ></a>
                      <a
                        href="javascript:void(0)"
                        onClick={() => handleEditModal(data.id)}
                        className="mdi mdi-pencil text-info display-5 ml-3"
                      ></a>
                    </td>
                  </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Add Standard Modal */}

      <div className="modal" id="addstandards" tabIndex="-1" role="dialog">
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Standard</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={editmode ? handleEdit : handleSubmit}>
              <div className="modal-body">
                <div className="form-group">
                  <label htmlFor="name">Standard</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    required
                    placeholder="Enter Standard"
                    onChange={handleSetForm}
                    value={StandardForm.name}
                  />
                </div>
               
                <div className="form-group">
                  <label htmlFor="board">Select Board</label>
                  <select
                    className="form-control"
                    style={{width:"100%"}}
                    name="boardId"
                    onClick={handleSelectBoard}
                    onKeyDown={handleSelectBoard}
                    onChange={handleSetForm}
                    value={StandardForm.boardId}
                    required
                  >
                    <option value="">--Select Board--</option>
                    {boards.map(board => (
                      <option key={board.id} value={board.id} selected={board.id === StandardForm.boardId}>{board.name}</option>
                    ))}
                  </select>
                </div>
               
                <div className="form-group">
                  <label htmlFor="sortOrder">Order</label>
                  <input
                    type="text"
                    className="form-control"
                    name="sortOrder"
                    placeholder="Enter Sort Order"
                    onChange={handleSetForm}
                    value={StandardForm.sortOrder}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-primary">
                 {editmode ? "Update Standard" : "Add Standard"}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => {
                    addstandardmodal.style.display = "none";
                  }}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Delete Modal */}


      <div className="modal" id="stddelmodal" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content bg-white">
            <div className="modal-header d-flex justify-content-around align-items-center">
              <h1 className="modal-title text-danger"><span className="mdi mdi-close-circle-outline" style={{fontSize:"50px"}}></span></h1>
              <div className="text-danger text-center">
              <p style={{fontSize:"22px"}}>Are you sure you want to Delete?</p>
            </div>
            </div>
            
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"  onClick={()=>{
                  stddelmodal.style.display = "none";
                }}>Close</button>
              <button type="button" className="btn btn-danger" onClick={handleDelete}>Confirm</button>
            </div>
          </div>
        </div>
      </div>
   
    </>
  );
};

export default AddStandards;
