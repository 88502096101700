import React, { useState, useEffect } from "react";

import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../constants";
import { useFormik } from "formik";
import * as Yup from "yup";

const AddStudent = () => {
  const [boardslist, setboardslist] = useState([]);
  const [standardlist, setStandardList] = useState([]);
  const [error, setError] = useState(null);
  const [studentlist, setStudentList] = useState([]);
  const [studeleteId, setstuDeleteId] = useState(null);
  const [editmode, setEditMode] = useState(false);  
  const [studentForm, setStudentForm] = useState({
    userName: "",
    firstName: "",
    lastName: "",
    schoolCollegeName: "",
    boardId: "",
    standardId: "",
    fatherName: "",
    fMobileNumber: "",
    fEmailId: "",
    motherName: "",
    mMobileNumber: "",
    mEmailId: "",
    address: "",
    area: "",
    password: "",
    confirmPassword: "",
  });
  const baseURL = BASE_URL;
  const token = localStorage.getItem("erptoken");
  const addstudentmodal = document.querySelector("#addstudents");
  const studelmodal = document.querySelector("#studentdelmodal");

  const handleBoards = async () => {
    try {
      const response = await axios.get(`${baseURL}/api/Students/FetchBoards`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setboardslist(response.data);
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  };

  const handleStandards = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/api/Students/FetchStandards/${formik.values.boardId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setStandardList(response.data);
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  };

  const filterboardid = async (e) => {
    e.preventDefault();
    setStudentForm((studentForm) => ({
      ...studentForm,
      boardId: e.target.value,
    }));
    try {
      const response = await axios.get(
        `${baseURL}/api/Students/FilterData?boardid=${e.target.value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setStudentList(response.data);
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  };

  const handlefilterdata = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.get(
        `${baseURL}/api/Students/FilterData?boardid=${studentForm.boardId}&standardid=${event.target.value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setStudentList(response.data);
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  };

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/students`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setStudentList(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchStudentData();

    const fetchboards = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/api/Students/FetchBoards`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setboardslist(response.data);
      } catch (error) {
        console.error(error);
        toast.error(error);
      }
    };
    fetchboards();
    


  }, [editmode]);

  const handleStudentDeleteModal = (key) => {
    setstuDeleteId(key);
    studelmodal.style.display = "block";
  };

  const handleStudentDelete = () => {
    axios
      .delete(`${baseURL}/api/Students/${studeleteId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        toast.success("Student has been Successfully Deleted !");
        setStudentList(studentlist.filter((a) => a.id !== studeleteId));
        setstuDeleteId(null);
        studelmodal.style.display = "none";
      })
      .catch((error) => {
        console.error(error);
        toast.success(error);
        setstuDeleteId(null);
        studelmodal.style.display = "none";
      });
  };

  const handleEditModal = (key) => {
    console.log(key);
    setEditMode(true);
    setstuDeleteId(key);
    const editstudent = studentlist.filter((a) => a.id === key);

    setStudentForm({
      userName: editstudent[0].user.userName,
      firstName: editstudent[0].firstName,
      lastName: editstudent[0].lastName,
      schoolCollegeName: editstudent[0].schoolCollegeName,
      boardId: editstudent[0].boardId,
      standardId: editstudent[0].standardId,
      fatherName: editstudent[0].fatherName,
      fMobileNumber: editstudent[0].fMobileNumber,
      fEmailId: editstudent[0].fEmailId,
      motherName: editstudent[0].motherName,
      mMobileNumber: editstudent[0].mMobileNumber,
      mEmailId: editstudent[0].mEmailId,
      address: editstudent[0].address,
      area: editstudent[0].area,
    });

    addstudentmodal.style.display = "block";
  };


  const getValidationSchema = (editmode) => {
    return Yup.object({
      userName: Yup.string().min(2, "Too Short!").required("UserName is Required"),
      firstName: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("This Field is Required"),
      lastName: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("This Field is Required"),
      boardId: Yup.string().required("This Field is Required"),
      standardId: Yup.string().required("This Field is Required"),
      fatherName: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("This Field is Required"),
      fMobileNumber: Yup.string()
        .min(10, "Please Enter Valid Mobile Number")
        .max(10, "Please Enter Valid Mobile Number")
        .required("This Field is Required"),
      password: editmode ? Yup.string() : Yup.string().required("This Field is Required"),
      confirmPassword:  editmode ? Yup.string() : Yup.string()
            .oneOf([Yup.ref("password"), null], "Passwords must match!")
            .required("This Field is Required"),
    });
  };
  



  const formik = useFormik({
    initialValues: {
     ...studentForm
    },
    validationSchema: getValidationSchema(editmode),
    validateOnChange:false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      if (editmode) {
        try {
          const response = await axios.put(
            `${baseURL}/api/Students/${studeleteId}`,
            values,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const updatedstudentlist = studentlist.map((student) => {
            if (student.id === response.data.id) {
              return response.data;
            }
            return student;
          });

          setStudentList(updatedstudentlist);

          setStudentForm({
            userName: "",
            firstName: "",
            lastName: "",
            schoolCollegeName: "",
            boardId: "",
            standardId: "",
            fatherName: "",
            fMobileNumber: "",
            fEmailId: "",
            motherName: "",
            mMobileNumber: "",
            mEmailId: "",
            address: "",
            area: "",
            password: "",
            confirmPassword: "",
          });
          setstuDeleteId(null);
          setEditMode(false);
          document.querySelector("#addstudents").style.display = "none";
          toast.success("Student Has been Successfully Edited");
        } catch (error) {
          var modelstateerr = error.response.data;
          for (let err in modelstateerr) {
            toast.error(modelstateerr[err][0]);
          }
          setError(error.message);
        }
      } else {
        try {
          const response = await axios.post(`${baseURL}/api/Students`, values, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          console.log(response.data);
          setStudentList([...studentlist, response.data]);
          setStudentForm({
            userName: "",
            firstName: "",
            lastName: "",
            schoolCollegeName: "",
            boardId: "",
            standardId: "",
            fatherName: "",
            fMobileNumber: "",
            fEmailId: "",
            motherName: "",
            mMobileNumber: "",
            mEmailId: "",
            address: "",
            area: "",
            password: "",
            confirmPassword: "",
          });
          addstudentmodal.style.display = "none";
          toast.success("Student has been Added Successfully");
        } catch (error) {
          var modelstateerr = error.response.data;
          for (let err in modelstateerr) {
            toast.error(modelstateerr[err][0]);
          }
          setError(error.message);
        }
      }
    },
    
  });

  useEffect(() => {
    formik.setValues(studentForm);
  }, [studentForm]);


  console.log(formik.values);
  return (
    <>
      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between align-items-center pb-4">
                <h4 class="card-title m-0">Boards</h4>
                <div class="d-flex">
                  <select
                    class="form-control mx-1"
                    value={studentForm.boardId}
                    onKeyDown={handleBoards}
                    onClick={handleBoards}
                    onChange={filterboardid}
                  >
                    <option value="">--Select Board--</option>
                    {boardslist.map((data) => (
                      <option key={data.id} value={data.id}>
                        {data.name}
                      </option>
                    ))}
                  </select>
                  <select
                    class="form-control mx-1"
                    name="standardId"
                    onKeyDown={handleStandards}
                    onClick={handleStandards}
                    onChange={handlefilterdata}
                  >
                    <option value="">--Select Standard--</option>
                    {standardlist.map((data) => (
                      <option key={data.id} value={data.id}>
                        {data.name}
                      </option>
                    ))}
                  </select>
                </div>
                <a
                  href="javascript:void(0)"
                  class="btn btn-info"
                  onClick={() => {
                    setEditMode(false);
                    setStudentForm({
                      userName: "",
                      firstName: "",
                      lastName: "",
                      schoolCollegeName: "",
                      boardId: "",
                      standardId: "",
                      fatherName: "",
                      fMobileNumber: "",
                      fEmailId: "",
                      motherName: "",
                      mMobileNumber: "",
                      mEmailId: "",
                      address: "",
                      area: "",
                      password: "",
                    });
                    const modal = document.querySelector("#addstudents");
                    modal.style.display = "block";
                  }}
                >
                  <span class="mdi mdi-plus mr-1"></span>
                  <span>Add Students</span>
                </a>
              </div>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Student Name</th>
                      <th>Board</th>
                      <th>Standard</th>
                      <th>Mobile Number</th>
                      <th>Area</th>
                    </tr>
                  </thead>
                  <tbody>
                    {studentlist.map((data) => (
                      <tr key={data.id}>
                        <td>{data.firstName} {data.lastName}</td>
                        <td>{data.board.name}</td>
                        <td>{data.standard.name}</td>
                        <td>{data.fMobileNumber}</td>
                        <td>{data.area}</td>
                        <td>
                          <a
                            href="javascript:void(0)"
                            onClick={() => handleEditModal(data.id)}
                            class="mdi mdi-pencil text-info display-5"
                          ></a>
                          <a
                            href="javascript:void(0)"
                            onClick={() => handleStudentDeleteModal(data.id)}
                            class="mdi mdi-trash-can text-danger display-5"
                          ></a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal"
        id="addstudents"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                {editmode ? "Edit Student" : "Add Students"}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div class="modal-body p-0">
                <div class="row">
                  <div class="col-12 grid-margin mb-0">
                    <div class="card">
                      <div class="card-body">
                        <div class="row">
                        <div class="col-md-4">
                            <div class="form-group row">
                              <div class="col-sm-12">
                                <input
                                  type="text"
                                  name="userName"
                                  value={formik.values.userName}
                                  class="form-control"
                                  placeholder="UserName"
                                  onChange={formik.handleChange}
                                />
                                {formik.errors.userName && (
                                  <div class="formikerrors">
                                    {formik.errors.userName}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group row">
                              <div class="col-sm-12">
                                <input
                                  type="text"
                                  name="firstName"
                                  value={formik.values.firstName}
                                  class="form-control"
                                  placeholder="Enter Student's First Name"
                                  onChange={formik.handleChange}
                                />
                                {formik.errors.firstName && (
                                  <div class="formikerrors">
                                    {formik.errors.firstName}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group row">
                              <div class="col-sm-12">
                                <input
                                  type="text"
                                  name="lastName"
                                  value={formik.values.lastName}
                                  class="form-control"
                                  placeholder="Enter Student's Last Name"
                                  onChange={formik.handleChange}
                                />
                                {formik.errors.lastName && (
                                  <div class="formikerrors">
                                    {formik.errors.lastName}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group row">
                              
                              <div class="col-sm-12">
                                <input
                                  type="text"
                                  name="schoolCollegeName"
                                  value={formik.values.schoolCollegeName}
                                  class="form-control"
                                  placeholder="Enter's School / College Name"
                                  onChange={formik.handleChange}
                                />
                                {formik.errors.schoolCollegeName && (
                                  <div class="formikerrors">
                                    {formik.errors.schoolCollegeName}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group row">
                              <div class="col-sm-12">
                                <select
                                  class="form-control"
                                  name="boardId"
                                  value={formik.values.boardId}
                                  onKeyDown={handleBoards}
                                  onClick={handleBoards}
                                  onChange={formik.handleChange}
                                >
                                  <option value="">--Select Board--</option>
                                  {boardslist.map((data) => (
                                    <option key={data.id} value={data.id}>
                                      {data.name}
                                    </option>
                                  ))}
                                </select>
                                {formik.errors.boardId && (
                                  <div class="formikerrors">
                                    {formik.errors.boardId}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group row">
                              
                              <div class="col-sm-12">
                                <select
                                  class="form-control"
                                  name="standardId"
                                  value={formik.values.standardId}
                                  onKeyDown={handleStandards}
                                  onClick={handleStandards}
                                  onChange={formik.handleChange}
                                >
                                  <option value="">--Select Standard--</option>
                                  {standardlist.map((data) => (
                                    <option key={data.id} value={data.id}>
                                      {data.name}
                                    </option>
                                  ))}
                                </select>
                                {formik.errors.standardId && (
                                  <div class="formikerrors">
                                    {formik.errors.standardId}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-group row">
                              
                              <div class="col-sm-12">
                                <input
                                  class="form-control"
                                  name="fatherName"
                                  value={formik.values.fatherName}
                                  placeholder="Enter Father's Name"
                                  onChange={formik.handleChange}
                                />
                                {formik.errors.fatherName && (
                                  <div class="formikerrors">
                                    {formik.errors.fatherName}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group row">
                              
                              <div class="col-sm-12">
                                <input
                                  type="text"
                                  name="fMobileNumber"
                                  value={formik.values.fMobileNumber}
                                  class="form-control"
                                  placeholder="Enter Father's Mobile Number"
                                  onChange={formik.handleChange}
                                />
                                {formik.errors.fMobileNumber && (
                                  <div class="formikerrors">
                                    {formik.errors.fMobileNumber}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group row">
                              
                              <div class="col-sm-12">
                                <input
                                  type="text"
                                  class="form-control"
                                  value={formik.values.fEmailId}
                                  name="fEmailId"
                                  placeholder="Enter Father's Email ID"
                                  onChange={formik.handleChange}
                                />
                                {formik.errors.fEmailId && (
                                  <div class="formikerrors">
                                    {formik.errors.fEmailId}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-group row">
                              <div class="col-sm-12">
                                <input
                                  type="text"
                                  class="form-control"
                                  value={formik.values.motherName}
                                  name="motherName"
                                  placeholder="Enter Mother's Name"
                                  onChange={formik.handleChange}
                                />
                                {formik.errors.motherName && (
                                  <div class="formikerrors">
                                    {formik.errors.motherName}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group row">
                              
                              <div class="col-sm-12">
                                <input
                                  type="text"
                                  class="form-control"
                                  value={formik.values.mMobileNumber}
                                  name="mMobileNumber"
                                  placeholder="Enter Mother's Mobile Number"
                                  onChange={formik.handleChange}
                                />
                                {formik.errors.mMobileNumber && (
                                  <div class="formikerrors">
                                    {formik.errors.mMobileNumber}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group row">
                              
                              <div class="col-sm-12">
                                <input
                                  type="text"
                                  class="form-control"
                                  value={formik.values.mEmailId}
                                  name="mEmailId"
                                  placeholder="Enter Mother's Email ID"
                                  onChange={formik.handleChange}
                                />
                                {formik.errors.mEmailId && (
                                  <div class="formikerrors">
                                    {formik.errors.mEmailId}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-8">
                            <div class="form-group row">
                            
                              <div class="col-sm-12">
                                <input
                                  type="text"
                                  class="form-control"
                                  value={formik.values.address}
                                  name="address"
                                  placeholder="Enter Student's Address"
                                  onChange={formik.handleChange}
                                />
                                {formik.errors.address && (
                                  <div class="formikerrors">
                                    {formik.errors.address}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group row">
                              
                              <div class="col-sm-12">
                                <input
                                  type="text"
                                  class="form-control"
                                  value={formik.values.area}
                                  name="area"
                                  placeholder="Enter Student's Area"
                                  onChange={formik.handleChange}
                                />
                                {formik.errors.area && (
                                  <div class="formikerrors">
                                    {formik.errors.area}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      
                            <div class="row">
                              <div class="col-md-4">
                                <div class="form-group row">
                                  
                                  <div class="col-sm-12">
                                    <input
                                      type="text"
                                      class="form-control"
                                      value={formik.values.password}
                                      name="password"
                                      placeholder={editmode ? "Change Password" : "Create Password"}
                                      onChange={formik.handleChange}
                                    />
                                    {formik.errors.password && (
                                      <div class="formikerrors">
                                        {formik.errors.password}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              {!editmode &&
                              <div class="col-md-4">
                                <div class="form-group row">
                                  
                                  <div class="col-sm-12">
                                    <input
                                      type="text"
                                      class="form-control"
                                      value={formik.values.confirmPassword}
                                      name="confirmPassword"
                                      placeholder="Confirm Password"
                                      onChange={formik.handleChange}
                                    />
                                    {formik.errors.confirmPassword && (
                                      <div class="formikerrors">
                                        {formik.errors.confirmPassword}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
}
                            </div>
                           </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn btn-primary mr-2"
                  disabled={formik.isSubmitting}
                >
                  {editmode ? "Edit Student" : "Add Student"}
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => {
                    const modal = document.querySelector("#addstudents");
                    modal.style.display = "none";
                    setStudentForm({
                      userName: "",
                      firstName: "",
                      lastName: "",
                      schoolCollegeName: "",
                      boardId: "",
                      standardId: "",
                      fatherName: "",
                      fMobileNumber: "",
                      fEmailId: "",
                      motherName: "",
                      mMobileNumber: "",
                      mEmailId: "",
                      address: "",
                      area: "",
                      password: "",
                    });
                  }}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Delete Modal */}

      <div class="modal" id="studentdelmodal" tabIndex="-1">
        <div class="modal-dialog modal-dialog-centered modal-sm">
          <div class="modal-content bg-white">
            <div class="modal-header d-flex justify-content-around align-items-center">
              <h1 class="modal-title text-danger">
                <span
                  class="mdi mdi-close-circle-outline"
                  style={{ fontSize: "50px" }}
                ></span>
              </h1>
              <div class="text-danger text-center">
                <p style={{ fontSize: "22px" }}>
                  Are you sure you want to Delete?
                </p>
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  const studentdel = document.querySelector("#studentdelmodal");
                  studentdel.style.display = "none";
                }}
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-danger"
                onClick={handleStudentDelete}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddStudent;
