import React, { useState } from "react";
import { toast } from 'react-toastify';
import axios from "axios";
import { BASE_URL } from '../constants';

const StudentMarks = () => {
  const [boardList , setBoardList] = useState([]);
  const [standardList , setStandardList] = useState([]);
  const [testList, setTestList] = useState([]);
  const [testId , setTestId] = useState([]);
  const [fetchData , setFetchData] = useState({
    boardId : null,
    standardId : null,
  });
  const [testStudentList ,setTestStudentList ] = useState();

  const baseURL = BASE_URL; 
  const token = localStorage.getItem('erptoken');

  const handlefetchboards = async () => {
    try {
      const response = await axios.get(`${baseURL}/api/Students/FetchBoards`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setBoardList(response.data);
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  }

  const handlefetchstandards = async () => {
    try {
      const response = await axios.get(`${baseURL}/api/Students/FetchStandards/${fetchData.boardId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setStandardList(response.data);
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  };

  const handlefetchtestdata = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.get(`${baseURL}/api/Students/FetchTestStudents?boardid=${fetchData.boardId}&standardid=${fetchData.standardId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTestList(response.data);
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  };

  const handleSetFetchData = (event) => {
    setFetchData({...fetchData , [event.target.name] : event.target.value})
  }

  const handleteststudentdata = async (e) => {
    try {
      const response = await axios.get(`${baseURL}/api/Tests/TestStudents/${testId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTestStudentList(response.data);
      setTestId(null);
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  };


  const handleSetMarksObtained = (event , id) => {
    const value = event.target.value;
    setTestStudentList(prevState => {
      const index = prevState.findIndex(item => item.id === id);
      const updatedItem = {
        ...prevState[index],
        marksObtained: value
      };
      const updatedList = [...prevState];
      updatedList[index] = updatedItem;
      return updatedList;
    });
  };


  const handleUpdateMarks = async (event , id) => {
    event.preventDefault();
    const item = testStudentList.find((item) => item.id === id);
    const { id: itemId, marksObtained } = item;
    try {
      await axios.put(`${baseURL}/api/TestMarks/${itemId}`, {marksObtained} ,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const updatedList = testStudentList.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            marksObtained,
            isUpdated: true,
          };
        }
        return item;
      });
      setTestStudentList(updatedList);
    } catch (error) {
      console.error(error);
      toast.error(error);
    }


  };


  return (
    <>
      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <h4>Add Student Marks</h4>
                <div className="d-flex align-items-center">
                  <select class="form-control mx-1" name="boardId" value={fetchData.boardId} onClick={handlefetchboards} onKeyDown={handlefetchboards} onChange={handleSetFetchData}>
                    <option value="">--Select Board--</option>
                    {boardList.map((data) => (
                      <option value={data.id} key={data.id}>{data.name}</option>
                    ))}

                  </select>
                  <select class="form-control mx-1" name="standardId" value={fetchData.standardId} onClick={handlefetchstandards} onKeyDown={handlefetchstandards} onChange={handleSetFetchData}>
                    <option value="">--Select Standard--</option>
                    {standardList.map((data) => (
                      <option value={data.id} key={data.id}>{data.name}</option>
                    ))}
                  </select>
                  <select class="form-control mx-1" name="testId" value={testId} onClick={handlefetchtestdata} onKeyDown={handlefetchtestdata} onChange={(e) => { setTestId(e.target.value)}}>
                    <option value="">--Select Test--</option>
                    {testList.map((data) => (
                      <option value={data.id} key={data.id}>{data.testName} | {new Date(data.date).toLocaleDateString()}</option>
                    ))}
                  </select>
                  <a onClick={handleteststudentdata} className="btn btn-info w-50" style={{padding: "11px"}}>Get List</a>
                </div>
              </div>
              {testStudentList &&
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Subject</th>
                      <th>Date</th>
                      <th>Student Name</th>
                      <th>Marks Obtained</th>
                      <th>Out Of Marks</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {testStudentList && testStudentList.map((data) => (
                    <tr key={data.id}>
                      <td>{data.test.testName}</td>
                      <td>{new Date(data.test.date).toLocaleDateString()}</td>
                      <td>{data.student.name}</td>
                      <td> <input
                          type="text"
                          className="form-control"
                          name="marksObtained"
                          placeholder="Marks Obtained"
                          value={data.marksObtained}
                          onKeyPress={(event) => {
                            const keyCode = event.which || event.keyCode;
                            const keyValue = String.fromCharCode(keyCode);
                            if (!/^\d+$/.test(keyValue)) {
                              event.preventDefault();
                            }
                          }}
                          onChange={(event) => handleSetMarksObtained(event , data.id)}
                        />
                  </td>
                  <td>{data.test.outOfMarks}</td>
                      <td className="d-flex align-items-center">
                        <a class="badge badge-primary" onClick={(event) => handleUpdateMarks(event , data.id)}>Update</a>
                        {data.isUpdated && <span class="mdi mdi-update text-success display-5 ml-2"></span>}
                      </td>
                    </tr>
                    ))}
                   
                  </tbody>
                </table>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentMarks;
